import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Inject, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Configuration, NucleusApiModule } from '@geneious/nucleus-api-client';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { environment } from '../../environments/environment';
import { APP_CONFIG, AppConfig } from '../app.config';
import { FeaturesEffects } from '../features/feature-switch/store/features.effects';
import { GridModule } from '../features/grid/grid.module';
import { ReportModule } from '../features/report/report.module';
import { OverlaysModule } from '../shared/overlays/overlays.module';
import { GaalServerEffects } from './GaalServer.effects';
import { AuthEffects } from './auth/auth.effects';
import { DocumentTableEditEffects } from './document-table-edits/document-table-edits.effects';
import { DocumentTableServiceModule } from './document-table-service/document-table-service.module';
import { FilesTableModule } from './files-table/files-table.module';
import { ExpandEffects } from './folders/store/expand.effects';
import { FolderEffects } from './folders/store/folder.effects';
import { GridSelectionStateModule } from './grid-selection-state/grid-selection-state.module';
import { GridStateStoreEffects } from './grid-state/grid-state.effects';
import { httpInterceptors } from './interceptors';
import { JobsEffects } from './jobs/store/job-results/job-results.effects';
import { JobsStatusEffects } from './jobs/store/jobs-status/jobs-status.effects';
import { materialImports } from './material-imports.constant';
import { NgsComparisonsGraphDataStoreEffects } from './ngs/ngs-comparisons-graphs/ngs-comparisons-graph-data-store/ngs-comparisons-graph-data-store.effects';
import { NgsGraphDataStoreEffects } from './ngs/ngs-graphs/ngs-graph-data-store/ngs-graph-data-store.effects';
import { NGSEffects } from './ngs/store/ngs.effects';
import { OrganizationSettingsEffects } from './organization-settings/organization-settings.effects';
import { metaReducers, reducers } from './root.reducer';
import { CustomRouteReuseStrategy, appRoutes } from './router/app.routing';
import { FileUploadEffects } from './upload/uploads-table/uploads.effects';
import { DismissibleEffects } from './user-settings/dismissible/dismissible.effects';
import { FormStateEffects } from './user-settings/form-state/form-state.effects';
import { PipelineOptionsEffects } from './user-settings/pipeline-options/pipeline-options.effects';
import { ProfilesEffects } from './user-settings/profiles/profiles.effects';
import { SequenceViewerPreferencesEffects } from './user-settings/sequence-viewer-preferences/sequence-viewer-preferences.effects';
import { TablePreferencesEffects } from './user-settings/table-preferences/table-preferences.effects';
import { UISettingsEffects } from './user-settings/ui-settings/ui-settings.effects';
import { UserSettingsEffects } from './user-settings/user-settings.effects';
import {
  FULL_VIEW_MODE_VIEWER_COMPONENTS,
  PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS,
  PREVIEW_MODE_VIEWER_COMPONENTS,
} from './viewer-components/viewer-components';
import { ViewersStateStore } from './viewers-state/viewers-state-store';
import { ViewersStateModule } from './viewers-state/viewers-state.module';
import { ViewersState } from './viewers-v2/viewers-state/viewers-state';
import { ViewersV2Module } from './viewers-v2/viewers-v2.module';
import { GraphSidebarOptionsEffects } from './user-settings/graph-sidebar-options/graph-sidebar-options.effects';

const devImports: ModuleWithProviders<unknown>[] = [];
if (!environment.production) {
  devImports.push(
    StoreDevtoolsModule.instrument({ maxAge: 100, autoPause: true, connectInZone: true }),
  );
}

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forRoot(appRoutes, {
      paramsInheritanceStrategy: 'always',
    }),
    BrowserAnimationsModule,
    NucleusApiModule.forRoot(() => new Configuration()),
    ...materialImports,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        // TODO Look into enabling all of these runtime checks.
        strictStateSerializability: false,
        strictActionSerializability: false,
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictActionWithinNgZone: false,
      },
    }),
    EffectsModule.forRoot([
      AuthEffects,
      GridStateStoreEffects,
      FormStateEffects,
      TablePreferencesEffects,
      ProfilesEffects,
      OrganizationSettingsEffects,
      GaalServerEffects,
      UserSettingsEffects,
      SequenceViewerPreferencesEffects,
      JobsEffects,
      FileUploadEffects,
      FeaturesEffects,
      NGSEffects,
      DocumentTableEditEffects,
      ExpandEffects,
      FolderEffects,
      JobsStatusEffects,
      NgsComparisonsGraphDataStoreEffects,
      NgsGraphDataStoreEffects,
      PipelineOptionsEffects,
      UISettingsEffects,
      NgsGraphDataStoreEffects,
      DismissibleEffects,
      GraphSidebarOptionsEffects,
    ]),
    StoreRouterConnectingModule.forRoot(),
    ...devImports,
    GridModule,
    ViewersV2Module.withComponents(
      PREVIEW_MODE_VIEWER_COMPONENTS,
      PREVIEW_MODE_COMPLEX_DOCUMENT_VIEWER_COMPONENTS,
      FULL_VIEW_MODE_VIEWER_COMPONENTS,
    ),
    DocumentTableServiceModule,
    FilesTableModule,
    FontAwesomeModule,
    GridSelectionStateModule,
    OverlaysModule,
    RecaptchaV3Module,
    ReportModule,
    ViewersStateModule,
  ],
  providers: [
    httpInterceptors,
    {
      provide: ViewersState,
      useClass: ViewersStateStore,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: (config: AppConfig) => config.recaptchaSiteKey,
      deps: [APP_CONFIG],
    },
    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    @Inject(APP_CONFIG) appConfig: AppConfig,
    apiConfiguration: Configuration,
  ) {
    if (parentModule) {
      throw new Error(
        `Core Module has already been loaded. Import Core module in the AppModule only.`,
      );
    }
    apiConfiguration.basePath = appConfig.nucleusApiBaseUrl;
  }
}
