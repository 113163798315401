import {
  JobConfigurationWithSelection,
  OutputOptions,
  SelectionOptionsV1,
} from './jobParameters.model';
import {
  AntibodyAnnotatorOptionValues,
  SingleCellOptionValues,
} from '../../../app/core/pipeline-dialogs/antibody-annotator/antibody-annotator-option-values.model';

export type AnnotatorMethod = 'AntibodyAnnotator' | 'NgsAnnotator' | 'SingleClone';
export class PeptideAnnotatorJobParametersV1<T extends AnnotatorMethod>
  implements JobConfigurationWithSelection
{
  pipeline = {
    name: 'peptide-annotator',
    version: 1,
  };
  parameters: {
    selection: SelectionOptionsV1;
    options: PeptideAnnotatorJobOptionsV1<T>;
    output?: OutputOptions;
  };

  constructor(
    selection: SelectionOptionsV1,
    options: PeptideAnnotatorJobOptionsV1<T>,
    output?: OutputOptions,
  ) {
    this.parameters = { selection, options, output };
  }
}

export interface PeptideAnnotatorJobOptionsV1<T extends AnnotatorMethod> {
  resultName: string;
  optionValues: T extends 'AntibodyAnnotator'
    ? AntibodyAnnotatorOptionValues
    : SingleCellOptionValues;
}
