import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, filter, map } from 'rxjs/operators';
import {
  fetchUserSettingsByKindFromServer,
  fetchUserSettingsSuccess,
  upsertUserSetting,
} from '../user-settings.actions';
import { UserSettingsKinds } from '../user-settings-types';
import { Injectable } from '@angular/core';
import {
  fetchAllGraphSidebarOptions,
  setAllGraphSidebarOptions,
  setGraphSidebarOptionsForGraph,
} from './graph-sidebar-options.actions';
import {
  GraphSidebarOptions,
  GraphSidebarOptionsForGraph,
} from '../../../features/graphs/graph-sidebar';

@Injectable()
export class GraphSidebarOptionsEffects {
  constructor(private actions$: Actions) {}

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(setGraphSidebarOptionsForGraph),
      debounceTime(500),
      map(({ id, options }) =>
        upsertUserSetting({
          kind: UserSettingsKinds.GRAPH_SIDEBAR_OPTIONS,
          name: id,
          data: options,
        }),
      ),
    );
  });

  fetchAllOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchAllGraphSidebarOptions),
      map(() =>
        fetchUserSettingsByKindFromServer({ kind: UserSettingsKinds.GRAPH_SIDEBAR_OPTIONS }),
      ),
    ),
  );

  fetchAllOptionsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchUserSettingsSuccess),
      filter(({ kind }) => kind === UserSettingsKinds.GRAPH_SIDEBAR_OPTIONS),
      map(({ userSettings }) => {
        const graphSidebarOptions = userSettings.reduce((acc, userSetting) => {
          acc[userSetting.name] = userSetting.data as GraphSidebarOptionsForGraph;
          return acc;
        }, {} as GraphSidebarOptions);
        return setAllGraphSidebarOptions({ graphSidebarOptions });
      }),
    ),
  );
}
