import { createReducer, on } from '@ngrx/store';
import { GraphSidebarOptions } from '../../../features/graphs/graph-sidebar';
import {
  setAllGraphSidebarOptions,
  setGraphSidebarOptionsForGraph,
} from './graph-sidebar-options.actions';

export const initialGraphSidebarOptionsState: GraphSidebarOptions = {};
export const graphSidebarOptionsReducer = createReducer(
  initialGraphSidebarOptionsState,
  on(setGraphSidebarOptionsForGraph, (graphSidebarOptions, { id, options }) => {
    return {
      ...graphSidebarOptions,
      [id]: options,
    };
  }),
  on(setAllGraphSidebarOptions, (_, { graphSidebarOptions }) => graphSidebarOptions),
);
