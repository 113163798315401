// @ts-ignore
import { parse_newick } from '@geneious/biojs-io-newick';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '../../../core.store';
import {
  DocumentDatasourceParams,
  DocumentServiceResource,
} from '../../../../../nucleus/services/documentService/document-service.resource';
import { GridStateService } from '../../../grid-state/grid-state.service';
import { catchError, filter, map, shareReplay, switchMap, take, takeWhile } from 'rxjs/operators';
import { IGetRowsRequestMinimal } from '../../../../features/grid/datasource/grid.resource';
import { asyncScheduler, forkJoin, Observable, observeOn, of, throwError } from 'rxjs';
import { selectGraphParamsForNgsDocument } from './ngs-graph-data-store.selectors';
import { NgsReportService } from '../../ngs-report-viewer/ngs-report.service';
import {
  AggregatedDocumentQuery,
  DocumentServiceDocumentQuery,
} from '../../../../../nucleus/services/documentService/document-service.v1.http';
import { DocumentTableQueryService } from '../../../document-table-service/document-table-state/document-table-query.service';
import {
  AggregateData,
  AlignmentType,
  ClusterSummaryData,
  ClusterSummaryDataFromReport,
  ExtraSelectionDataFor,
  GraphDataFor,
  GraphId,
} from '../ngs-graphs.model';
import {
  getDefaultTableSelection,
  GraphSelectionDataFor,
  isIgnorableParameterForGraph,
  SelectionForGraph,
  TableForGraph,
} from './ngs-graph-data-store.reducer';
import { ngsGraphActions, possibleParams } from './ngs-graph-data-store.actions';
import { NgsClusterDiversityGraphComponent } from '../ngs-cluster-graphs/ngs-cluster-diversity-graph/ngs-cluster-diversity-graph.component';
import { NgsClusterLengthsGraphComponent } from '../ngs-cluster-graphs/ngs-cluster-lengths-graph/ngs-cluster-lengths-graph.component';
import { NgsClusterSizesGraphComponent } from '../ngs-cluster-graphs/ngs-cluster-sizes-graph/ngs-cluster-sizes-graph.component';
import { GraphDocumentDataService } from '../../../../features/graphs/graph-document-data.service';
import { GridData } from '../../../../features/graphs/graph-util.service';
import { HeatmapData } from '../../../../features/graphs/graph-heatmap/graph-heatmap.component';
import { GeneCombinationsHeatmapService } from '../../../../features/graphs/gene-combinations-heatmap.service';
import { assertNever } from '../../../../shared/utils/never';
import {
  orderByToSortModel,
  sanitizeDTSTableOrColumnName,
} from '../../../../../nucleus/services/documentService/document-service.v1';
import { AggregationKind, OrderByTableQueryKind } from '@geneious/nucleus-api-client';
import { DocumentHttpV2Service } from '../../../../../nucleus/v2/document-http.v2.service';
import { TreeGraphDatasource } from '../../../../features/graphs/datasources/tree-graph-datasource';

@Injectable()
export class NgsGraphDataStoreEffects {
  constructor(
    private actions: Actions,
    private store: Store<AppState>,
    private documentServiceResource: DocumentServiceResource,
    private gridStateService: GridStateService,
    private ngsReportService: NgsReportService,
    private documentTableQueryService: DocumentTableQueryService,
    private graphDocumentDataService: GraphDocumentDataService,
    private geneCombinationsHeatmapService: GeneCombinationsHeatmapService,
    private documentHttpService: DocumentHttpV2Service,
  ) {}

  setTableSelectionToDefault$ = createEffect(() =>
    this.actions.pipe(
      ofType(ngsGraphActions.params.selectedTable.update),
      filter(({ graphId }) => graphId !== 'noGraph'),
      map(({ id, graphId }) =>
        ngsGraphActions.params.tableSelection.update({
          id,
          graphId,
          value: {
            tableSelection: getDefaultTableSelection(),
          },
        }),
      ),
    ),
  );

  triggerGraphDataUpdate$ = createEffect(() =>
    this.actions.pipe(
      ofType(...possibleParams.map((param) => ngsGraphActions.params[param].update)),
      switchMap(({ id, graphId }) => {
        return this.store.select(selectGraphParamsForNgsDocument(id)).pipe(
          take(1),
          filter(({ currentSelection }) =>
            allParamsValid(
              currentSelection.selectedGraph.valid ? currentSelection.selectedGraph.value : graphId,
              currentSelection,
            ),
          ),
          map(({ currentSelection }) => {
            return ngsGraphActions.data.fetchGraphData({
              id,
              graphId,
              tableName: currentSelection.selectedTable.value.name,
            });
          }),
        );
      }),
    ),
  );

  fetchGraphData$ = createEffect(() =>
    this.actions.pipe(
      ofType(ngsGraphActions.data.fetchGraphData),
      switchMap(({ id, graphId }) =>
        this.store.select(selectGraphParamsForNgsDocument(id)).pipe(
          take(1),
          filter(({ currentSelection }) => currentSelection?.selectedGraph?.value !== 'noGraph'),
          switchMap(({ id, currentSelection }) =>
            this.fetchDataForGraph(id, graphId, currentSelection).pipe(
              map((data: GraphDataFor<typeof currentSelection.selectedGraph.value>) =>
                ngsGraphActions.data.fetchGraphDataSuccess({
                  id,
                  graphId,
                  data,
                  tableName: currentSelection.selectedTable.value.name,
                }),
              ),
              catchError((_) => {
                return of(
                  ngsGraphActions.data.fetchGraphDataFail({
                    id,
                    graphId,
                    tableName: currentSelection.selectedTable.value.name,
                  }),
                );
              }),
            ),
          ),
        ),
      ),
    ),
  );

  fetchDataForGraph(
    id: string,
    graphId: GraphId,
    currentSelection: GraphSelectionDataFor<typeof graphId>,
  ): Observable<GraphDataFor<typeof graphId>> {
    const additionalSelectionData: ExtraSelectionDataFor<typeof graphId> =
      getAdditionalSelectionDataFor(graphId, currentSelection);
    const whereClause =
      currentSelection.selectionDisplayType.value !== 'all'
        ? getDatasourceParamsForSelection(
            id,
            currentSelection.tableSelection.value,
            currentSelection.filter.value,
            currentSelection.selectedTable.value,
            currentSelection.selectionDisplayType.value === 'selected',
          ).filterModel
        : null;
    switch (graphId) {
      case 'annotationRates':
      case 'numberOfGenes':
      case 'clusterNumbers':
      case 'clusterNumbersNucleotide':
      case 'geneFamilyUsage':
        return this.fetchReportData<typeof graphId>(id);
      case 'clusterDiversity':
      case 'clusterLengths':
        if (!additionalSelectionData) {
          return throwError(() => new Error('no aggregate query provided'));
        }
        return this.fetchTableDataWithAggregateQuery(
          id,
          currentSelection.selectedTable.value.name,
          {
            ...(additionalSelectionData as ExtraSelectionDataFor<
              'clusterLengths' | 'clusterDiversity'
            >),
            where: whereClause,
          },
        );
      case 'clusterSizes':
        if (!additionalSelectionData) {
          return throwError(() => new Error('no aggregate query provided'));
        }
        return this.fetchTableDataWithCursorQuery(
          id,
          currentSelection.selectionDisplayType.value !== 'all'
            ? currentSelection.tableSelection.value
            : getDefaultTableSelection(),
          whereClause,
          currentSelection.selectedTable.value,
          additionalSelectionData as ExtraSelectionDataFor<'clusterSizes'>,
        );
      case 'aminoAcidDistribution':
        if (!additionalSelectionData) {
          return throwError(() => new Error('no length option provided'));
        }
        const regionOption = {
          name: currentSelection.selectedTable.value.displayName,
          table: currentSelection.selectedTable.value.name,
          tableType: currentSelection.selectedTable.value.tableType,
          columns: currentSelection.selectedTable.value.columns,
          metadata: currentSelection.selectedTable.value.metadata,
        };
        return this.graphDocumentDataService.getAminoAcidDistribution(
          id,
          regionOption,
          (additionalSelectionData as ExtraSelectionDataFor<'aminoAcidDistribution'>).length,
        );
      case 'codonDistribution':
        if (!additionalSelectionData) {
          return throwError(() => new Error('no aggregate query provided'));
        }
        const region = {
          name: currentSelection.selectedTable.value.displayName,
          table: currentSelection.selectedTable.value.name,
          tableType: currentSelection.selectedTable.value.tableType,
          columns: currentSelection.selectedTable.value.columns,
          metadata: currentSelection.selectedTable.value.metadata,
        };
        const length = (additionalSelectionData as ExtraSelectionDataFor<'codonDistribution'>)
          .length;
        if (length === 'All lengths') {
          return forkJoin([
            this.graphDocumentDataService.getFormattedCodonUsageSummaryData(
              id,
              region,
              true,
            ) as Observable<GridData>,
            this.graphDocumentDataService.getFormattedCodonUsageSummaryData(
              id,
              region,
              false,
            ) as Observable<HeatmapData>,
          ]).pipe(map(([table, heatmap]) => ({ table, heatmap })));
        }
        return forkJoin([
          this.graphDocumentDataService.getFormattedCodonUsageDataByLength(
            id,
            region,
            length,
            true,
          ) as Observable<GridData>,
          this.graphDocumentDataService.getFormattedCodonUsageDataByLength(
            id,
            region,
            length,
            false,
          ) as Observable<HeatmapData>,
        ]).pipe(map(([table, heatmap]) => ({ table, heatmap })));
      case 'geneCombinations':
        if (!additionalSelectionData) {
          return throwError(
            () =>
              new Error(
                'Additional selection data (e.g. table options) for gene combinations not provided',
              ),
          );
        }
        if (
          (additionalSelectionData as ExtraSelectionDataFor<'geneCombinations'>)
            .computeHeatmapFromTable
        ) {
          const { tableData } =
            additionalSelectionData as ExtraSelectionDataFor<'geneCombinations'>;
          return this.geneCombinationsHeatmapService
            .getHeatmapDataFromChainCombinations(
              id,
              'DOCUMENT_TABLE_CHAIN_COMBINATIONS',
              tableData.geneCombination,
            )
            .pipe(
              map((data) => ({
                statistics: {
                  geneCombinations: {
                    [tableData.geneCombination]: data,
                  },
                },
              })),
            );
        } else {
          return this.fetchReportData<'geneCombinations'>(id);
        }
      case 'sankey':
        if (!additionalSelectionData) {
          return throwError(() => new Error('no cluster information provided'));
        }
        const { regions, topCount, table } =
          additionalSelectionData as ExtraSelectionDataFor<'sankey'>;
        return this.fetchSankeyData(id, table, regions, topCount);
      case 'clusterSummaryTree':
      case 'clusterSummaryNetwork':
        return this.fetchClusterSummaryData(
          id,
          currentSelection.selectedTable.value,
          currentSelection.tableSelection.value,
        );
      case 'comparisonsScatterplot':
      case 'comparisonsHistogram':
      case 'noGraph':
        return throwError(() => new Error('Tried to fetch data for empty graph'));
      default:
        return assertNever(graphId, 'Unexpected case');
    }
  }

  fetchClusterSummaryData(
    id: string,
    table: TableForGraph,
    selection: SelectionForGraph,
  ): Observable<ClusterSummaryData> {
    return this.ngsReportService.getClusterSummaryById(id, table.displayName).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
      observeOn(asyncScheduler),
      switchMap((data) => {
        const tableName = sanitizeDTSTableOrColumnName(table.displayName);
        let FIELDS_TO_FETCH = [
          `${tableName} ID`,
          `${tableName}`,
          'Length',
          'Total',
          '# of Sequences',
          '# of Clones',
          'Frequency %',
          '# Exact Clusters',
          'Primary Exact Cluster %',
          'Evenness',
          '% Fully Annotated',
          '% In Frame (& Fully Annotated)',
          '% Without Stop Codons (& In Frame)',
        ];
        FIELDS_TO_FETCH.push(...FIELDS_TO_FETCH.map((field) => `${field} (by Clone)`));
        const ids = Object.keys(data.idToIndex).map((x) => `${parseInt(x)}`);
        const params: DocumentServiceDocumentQuery = {
          fields: FIELDS_TO_FETCH,
          where: getIdsAsRangeBetweenClause(ids, `['${tableName} ID']`),
          orderBy: [{ field: `${tableName} ID`, kind: OrderByTableQueryKind.Ascending }],
        };
        return this.fetchTableDataWithCursorQuery(id, selection, '', table, params).pipe(
          map((result: any) => {
            const idColumn = `${tableName} ID`;
            const sequenceColumn = `${tableName}`;
            const sequences: Record<number, Record<string, any>> = result.data
              .map((row: any) => {
                let renamedRow: any = {
                  ...row,
                  ID: row[idColumn],
                  Sequence: row[sequenceColumn],
                };
                delete renamedRow[idColumn];
                delete renamedRow[sequenceColumn];
                return renamedRow;
              })
              .reduce((acc: Record<number, Record<string, any>>, val: Record<string, any>) => {
                const id = parseInt(val['ID']);
                return { ...acc, [id]: { metadata: val } };
              }, {});
            const { summaryDataByScoreType, idToIndex } = data as
              | ClusterSummaryDataFromReport
              | ClusterSummaryData;
            for (const scoreType in summaryDataByScoreType) {
              if (
                typeof summaryDataByScoreType[scoreType as AlignmentType].similarityTree ===
                'string'
              ) {
                summaryDataByScoreType[scoreType as AlignmentType].similarityTree =
                  TreeGraphDatasource.formatTreeData(
                    parse_newick(summaryDataByScoreType[scoreType as AlignmentType].similarityTree),
                  );
              }
            }
            return {
              sequences,
              totalSequences: selection.total,
              idToIndex,
              summaryDataByScoreType,
            } as ClusterSummaryData;
          }),
        );
      }),
    );
  }

  fetchTableDataWithCursorQuery(
    documentId: string,
    selection: SelectionForGraph,
    filter: string,
    selectedTable: TableForGraph,
    overrideParams: DocumentServiceDocumentQuery = {},
  ): Observable<GraphDataFor<'clusterSizes'>> {
    const selectedTableName = selectedTable.name;
    const endRow =
      selection.selectedRows.length === 0
        ? DocumentServiceResource.RESULT_SET_MAX
        : Math.min(selection.selectedRows.length, DocumentServiceResource.RESULT_SET_MAX);
    const datasourceParams: DocumentDatasourceParams = getDatasourceParamsForSelection(
      documentId,
      selection,
      filter,
      selectedTable,
    );
    const sortModel$ = overrideParams?.orderBy
      ? of(orderByToSortModel(overrideParams?.orderBy))
      : this.gridStateService.getGridStateSortModel(selectedTableName).pipe(
          takeWhile((state) => state == undefined, true),
          map((sortModel) => sortModel ?? []),
        );
    return sortModel$.pipe(
      switchMap((sortModel) => {
        const requestParams: IGetRowsRequestMinimal = {
          startRow: 0,
          endRow,
          sortModel,
          filterModel: null,
        };
        return this.documentServiceResource.query(
          requestParams,
          datasourceParams,
          !overrideParams?.limit,
          overrideParams,
        );
      }),
    );
  }

  fetchReportData<T extends GraphId>(id: string): Observable<GraphDataFor<T>> {
    return this.ngsReportService.getReportBlobByID(id);
  }

  fetchTableDataWithAggregateQuery(
    id: string,
    tableName: string,
    query: AggregatedDocumentQuery,
  ): Observable<AggregateData> {
    return this.documentTableQueryService
      .queryTableAggregate(id, tableName, query)
      .pipe(map(({ data }) => data));
  }

  fetchSankeyData(
    documentID: string,
    table: `DOCUMENT_TABLE_${'ALL_SEQUENCES' | 'CHAIN_COMBINATIONS'}`,
    clusters: string[],
    topCount: number,
  ): Observable<GraphDataFor<'sankey'>> {
    const source = `def field = doc.containsKey(params['clusterID'] + '.keyword') ? doc[params['clusterID'] + '.keyword'] : doc[params['clusterID']];
              def clusterID = field.size() == 0 || field.empty ? "-1"
                : field.value.toString() ==~ /[0-9]+(;[0-9]+)*/
                ? field.value.toString()
                : "-1";
              def splitClusterID = clusterID.splitOnToken(";");
              def list = new ArrayList();
              int topCount = params['topCount'];
              for (int i = 0; i < splitClusterID.length; i++) {
                def scValue = splitClusterID[i] ==~ /[0-9]+/ ? Integer.parseInt(splitClusterID[i]) : -1;
                list.add(scValue < 0 ? 'empty' : scValue > topCount ? 'other' : scValue.toString());
              }
              return list[0];`;
    return this.documentTableQueryService
      .searchTable(documentID, table, {
        fields: [],
        aggregations: clusters.map((c, i) => ({
          kind: AggregationKind.Terms,
          id: c,
          size: topCount + 10,
          script: {
            source,
            params: {
              clusterID: `${sanitizeDTSTableOrColumnName(c)} ID`,
              topCount: topCount,
            },
          },
          sort: [],
          subAggregations: [
            ...(i < clusters.length - 1 ? [clusters[i + 1]] : []).map((next) => ({
              kind: AggregationKind.Terms,
              id: next,
              size: topCount + 10,
              script: {
                source,
                params: {
                  clusterID: `${sanitizeDTSTableOrColumnName(next)} ID`,
                  topCount: topCount,
                },
              },
              sort: [],
            })),
            // include the cluster name in the results
            {
              kind: AggregationKind.Terms,
              id: 'name',
              size: 1,
              script: {
                source: `doc.containsKey(params['name'] + '.keyword') ? doc[params['name'] + '.keyword'] : doc[params['name']]`,
                params: {
                  name: `${sanitizeDTSTableOrColumnName(c)}`,
                },
              },
              sort: [],
            },
          ],
        })),
        limit: 0,
        offset: 0,
      })
      .pipe(
        map(({ data }) => {
          const { aggregations } = data;
          const clusterMatrix: Record<string, Record<string, number>> = {};
          const regionTotals: Record<string, number> = {};
          const clusterTotals: Record<string, number> = {};
          const clusterFrequencies: Record<string, number> = {};
          const clusterNames: Record<string, string> = {};
          for (const cluster in aggregations) {
            regionTotals[cluster] = 0;
            for (const bucket of (aggregations[cluster] as any).buckets) {
              const { key, doc_count, name } = bucket;
              if (key !== 'empty') {
                let clusterName = (name?.buckets[0]?.key ?? key).split(';')[0];
                if (clusterName.length > 15) {
                  clusterName = clusterName.slice(0, 15) + '...';
                }
                clusterNames[`${cluster}-${key}`] = clusterName;
                clusterTotals[`${cluster}-${key}`] = doc_count;
                regionTotals[cluster] += doc_count;
              }
            }
          }
          const total = Object.entries(clusterTotals)
            .map(([_, clusterTotal]) => clusterTotal)
            .reduce((acc, val) => acc + val, 0);
          for (const cluster in clusterTotals) {
            clusterFrequencies[cluster] = clusterTotals[cluster] / total;
          }
          for (const cluster in aggregations) {
            const clusterData = aggregations[cluster] as any;
            clusterData.buckets.forEach(({ key, doc_count, name, ...otherRegions }: any) => {
              if (key !== 'empty') {
                clusterMatrix[`${cluster}-${key}`] = {};
                for (const otherRegion in otherRegions) {
                  const otherClusterData: any = otherRegions[otherRegion];
                  for (const otherCluster of otherClusterData.buckets) {
                    if (otherCluster.key !== 'empty') {
                      clusterMatrix[`${cluster}-${key}`][`${otherRegion}-${otherCluster.key}`] =
                        otherCluster.doc_count;
                    }
                  }
                }
              }
            });
          }
          return {
            clusterMatrix,
            regionTotals,
            clusterTotals,
            clusterFrequencies,
            clusterNames,
          };
        }),
      );
  }
}

export function getDatasourceParamsForSelection(
  documentId: string,
  selection: SelectionForGraph,
  filter: string,
  selectedTable: TableForGraph,
  respectEmptySelection: boolean = false,
): DocumentDatasourceParams {
  // The selection logic isn't the most straightforward... when selectAll is true, the
  // selected rows indicate what's NOT selected. But when selectAll is false, the selected
  // rows indicate what IS selected.
  const someRowsSelected = selection.rows.length > 0;
  const isAllSelected = selection.selectAll && !someRowsSelected;
  const isAllExceptSelected = selection.selectAll && someRowsSelected;

  const ids = selection.rows;
  const isNoneSelected = !(isAllSelected || isAllExceptSelected || someRowsSelected);
  // Order of the if statements matters.
  if (isNoneSelected && respectEmptySelection) {
    return <DocumentDatasourceParams>{
      filterModel: '0 = 1', // a fun and easy way to say "nothing"
      documentTableName: selectedTable.name,
      documentId,
    };
  }
  if (isAllSelected || (isNoneSelected && !respectEmptySelection)) {
    return <DocumentDatasourceParams>{
      filterModel: filter,
      documentTableName: selectedTable.name,
      documentId,
    };
  } else if (isAllExceptSelected) {
    const filterModel = filter
      ? `${filter} AND ${selection.rowIdentifierColumnName} NOT IN (${ids})`
      : `${selection.rowIdentifierColumnName} NOT IN (${ids})`;
    return <DocumentDatasourceParams>{
      filterModel: filterModel,
      documentTableName: selectedTable.name,
      documentId,
    };
  } else if (someRowsSelected) {
    // The endpoint crashes if we specify an IN clause with more than 1000 indexes.
    // So when we have more than 1000 ids, then split them into ranges.
    let filterQuery;
    if (ids.length > 1000) {
      filterQuery = getIdsAsRangeBetweenClause(ids, selection.rowIdentifierColumnName);
    } else {
      filterQuery = `${selection.rowIdentifierColumnName} IN (${ids})`;
    }

    return <DocumentDatasourceParams>{
      filterModel: filterQuery,
      documentTableName: selectedTable.name,
      documentId,
    };
  }
}

export function getIdsAsRangeBetweenClause(
  ids: string[],
  rowIdentifier: string = 'geneious_row_index',
): string {
  if (ids.length <= 0) return '';
  ids = ids.sort((a, b) => +a - +b);
  let startRangeIndex = 0;
  let currentValue = +ids[0];
  let filterQuery = '';
  for (let i = 1; i < ids.length; i++) {
    const value = +ids[i];
    // If the value is not contiguous with the last value, end the current range, and start a new
    // range.
    if (currentValue + 1 !== value) {
      filterQuery = appendBetweenFilter(
        filterQuery,
        ids[startRangeIndex],
        ids[i - 1],
        rowIdentifier,
      );
      startRangeIndex = i;
    }
    currentValue = value;
  }
  return appendBetweenFilter(filterQuery, ids[startRangeIndex], ids[ids.length - 1], rowIdentifier);
}

export function appendBetweenFilter(
  existingFilter: string,
  betweenStart: string,
  betweenEnd: string,
  rowIdentifier: string,
) {
  if (existingFilter !== '') {
    existingFilter += ' OR ';
  }
  existingFilter += `${rowIdentifier} BETWEEN (${betweenStart}) AND (${betweenEnd})`;
  return existingFilter;
}

export function allParamsValid(
  graphId: GraphId,
  graphSelection: GraphSelectionDataFor<typeof graphId>,
) {
  if (!graphSelection) {
    return false;
  }
  return (<Array<keyof GraphSelectionDataFor<typeof graphId>>>Object.keys(graphSelection)).every(
    (key) => isIgnorableParameterForGraph(graphId, key) || graphSelection[key].valid,
  );
}

function getAdditionalSelectionDataFor(
  graphId: GraphId,
  currentSelection: GraphSelectionDataFor<typeof graphId>,
): ExtraSelectionDataFor<typeof graphId> {
  switch (graphId) {
    case 'clusterDiversity':
      return NgsClusterDiversityGraphComponent.aggregateQuery(
        currentSelection.selectedTable.value.displayName,
        currentSelection.selectedTable.value.metadata,
        currentSelection.selectedTable.value.columns.map((col) => col.displayName),
      ) as ExtraSelectionDataFor<typeof graphId>;
    case 'clusterLengths':
      return NgsClusterLengthsGraphComponent.aggregateQuery(
        currentSelection.selectedTable.value.metadata,
        currentSelection.selectedTable.value.columns.map((col) => col.displayName),
      ) as ExtraSelectionDataFor<typeof graphId>;
    case 'clusterSizes':
      return NgsClusterSizesGraphComponent.query(
        currentSelection.selectedTable.value.displayName,
        currentSelection.selectedTable.value.metadata,
        currentSelection.selectedTable.value.columns.map((col) => col.displayName),
      ) as ExtraSelectionDataFor<typeof graphId>;
    case 'aminoAcidDistribution':
    case 'codonDistribution':
    case 'geneCombinations':
    case 'sankey':
      return currentSelection?.options?.value;
    default:
      return null;
  }
}

export function selectionsAreEqual(x: SelectionForGraph, y: SelectionForGraph) {
  return (
    x.selectAll === y.selectAll &&
    x.total === y.total &&
    x.selectedRows.length === y.selectedRows.length &&
    x.selectedRows.every((row) => y.selectedRows.includes(row)) &&
    y.selectedRows.every((row) => x.selectedRows.includes(row)) &&
    x.rowIdentifierColumnName === y.rowIdentifierColumnName
  );
}

export function optionsAreEqual<T extends GraphId>(
  x: ExtraSelectionDataFor<T>,
  y: ExtraSelectionDataFor<T>,
): boolean {
  const xKeys = !!x ? Object.keys(x) : null;
  const yKeys = !!y ? Object.keys(y) : null;
  return (
    (!xKeys && !yKeys) ||
    (xKeys?.every((xKey) => yKeys?.includes(xKey) && (y as any)[xKey] === (x as any)[xKey]) &&
      yKeys?.every((yKey) => xKeys?.includes(yKey) && (y as any)[yKey] === (x as any)[yKey]))
  );
}
