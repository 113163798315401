import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { combineLatest, Observable, Subject } from 'rxjs';
import {
  distinctUntilChanged,
  first,
  map,
  share,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { NGSJobOptionsV1 } from '../../../../nucleus/services/models/ngsOptions.model';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { Chip } from '../../../shared/chips';
import { toggleControlsAvailabilityOnBoolean } from '../../../shared/form-helpers/toggle-controls-availability';
import { defaultRegionChips } from '../../../shared/regions-selector/regions-selector';
import {
  CombinationRegionChip,
  RegionSelectorChain,
} from '../../../shared/regions-selector/regions-selector.component';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { SelectOption } from '../../models/ui/select-option.model';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { PipelineService } from '../../pipeline/pipeline.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { clusterComboValidator } from '../../ngs/validators/cluster-combo-validator';
import { AntibodyAnnotatorBaseComponent } from './antibody-annotator-base.component';
import {
  AmbiguousGenesStrategy,
  AnnotatedGeneRange,
  annotationStyleOptions,
  AntibodyAnnotatorOptionValues,
  defaultClusterCombos,
  defaultClusterCombosGenes,
  defaultLiabilities,
  getPremiumOptions,
  getSelectedChain,
  SequencesAnnotationStyle,
  SequencesChain,
} from './antibody-annotator-option-values.model';
import {
  FormRawValue,
  currentValueAndChanges,
  restrictControlValue,
  restrictControlValues,
} from 'src/app/shared/utils/forms';
import { DismissibleDirective } from '../../user-settings/dismissible/dismissible.directive';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../shared/card/card.component';
import { MatIconModule } from '@angular/material/icon';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { NameSchemeSelectComponent } from '../../name-schemes/name-scheme-select/name-scheme-select.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { SelectComponent } from '../../../shared/select/select.component';
import { ClusteringOptionsCardComponent } from '../shared/clustering-options-card/clustering-options-card.component';
import { FRAdjustmentOptionsComponent } from '../shared/fr-adjustment-options/fr-adjustment-options.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-antibody-annotator',
  templateUrl: './antibody-annotator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DismissibleDirective,
    NgbAlert,
    CardComponent,
    NgbTooltip,
    MatIconModule,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    ShowIfDirective,
    NameSchemeSelectComponent,
    CollapsibleCardComponent,
    SelectComponent,
    ClusteringOptionsCardComponent,
    FRAdjustmentOptionsComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class AntibodyAnnotatorComponent extends AntibodyAnnotatorBaseComponent implements OnInit {
  referenceDatabases$: Observable<SelectOption[][]>;
  featureDatabases$: Observable<SelectOption[][]>;
  linkersEnabled$: Observable<boolean>;
  linkerDatabases$: Observable<SelectOption[]>;
  geneticCodes$: Observable<SelectOption[]>;
  associatedNameSchemes$: Observable<Set<string>>;
  selectedChain$: Observable<RegionSelectorChain>;
  multipleRefDbsEnabled$: Observable<boolean>;
  readonly form = this.initFormControls();

  enableThreeOrMoreSequencesInGroupOptions$: Observable<boolean>;
  readonly dismissAlert$: Subject<boolean> = new Subject();

  twoHeavyChainsEnabled$: Observable<boolean>;
  showNGSRecommendation$: Observable<boolean>;
  fullyAnnotatedTooltip$: Observable<string>;

  @Input() forMasterDatabase = false;

  readonly annotationStyleOptions = annotationStyleOptions;
  private readonly formDefaults = this.form.getRawValue();
  private readonly defaultMaxLinkerMismatchPercentage = 20;

  constructor(
    private readonly featureSwitchService: FeatureSwitchService,
    private readonly pipelineService: PipelineService,
    private readonly validatorService: PipelineFormControlValidatorsService,
    @Optional() @Inject(PIPELINE_DIALOG_DATA) dialogData: PipelineDialogData,
  ) {
    super(dialogData);
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.toggleControlsAvailability();
    this.linkersEnabled$ = this.featureSwitchService.isEnabledOnce('userSpecifiedLinkers');
    this.selectedChain$ = this.form.controls.sequences_chain.valueChanges.pipe(
      startWith(this.form.controls.sequences_chain.value),
      map(getSelectedChain),
    );

    this.referenceDatabases$ = this.pipelineService.getReferenceDatabases().pipe(
      first(),
      tap((options) => {
        const refDBControl = this.form.controls.database_customDatabase;
        const databaseIDs = options.flatMap((group) => group.map((option) => option.value));
        restrictControlValues(refDBControl, databaseIDs, { takeUntil: this.ngUnsubscribe });
        if (databaseIDs[0] && !refDBControl.value?.length) {
          refDBControl.setValue([databaseIDs[0]]);
        }
      }),
      share(),
    );

    this.featureDatabases$ = this.pipelineService.getDatabases(DatabaseTypeEnum.FEATURE).pipe(
      first(),
      tap((options) => {
        const values = options.flatMap((group) => group.map((option) => option.value));
        return restrictControlValue(this.form.controls.database_featureDatabase, values, {
          takeUntil: this.ngUnsubscribe,
          defaultValue: values[0],
        });
      }),
    );
    this.selectedChain$
      .pipe(
        map((chain) => chain == 'bothWithLinker' || chain == 'twoHeavyWithLinker'),
        distinctUntilChanged(),
      )
      .subscribe((result) => {
        if (result) {
          this.form.controls.database_linkerDatabase.enable();
        } else {
          this.form.controls.database_linkerDatabase.reset();
          this.form.controls.database_linkerDatabase.disable();
        }
      });

    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        map((formValues) => formValues.database_linkerDatabase),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((linkerDatabase) => {
        if (linkerDatabase) {
          this.form.controls.database_linkerDatabaseMismatches.enable();
          if (this.form.controls.database_linkerDatabaseMismatches.value == null) {
            this.form.controls.database_linkerDatabaseMismatches.setValue(
              this.defaultMaxLinkerMismatchPercentage,
            );
          }
        } else {
          this.form.controls.database_linkerDatabaseMismatches.reset();
          this.form.controls.database_linkerDatabaseMismatches.disable();
        }
      });

    this.linkerDatabases$ = this.pipelineService.getDatabases(DatabaseTypeEnum.LINKER).pipe(
      first(),
      map((databases) => databases.flat()),
      share(),
    );

    this.geneticCodes$ = this.pipelineService.externalServices.geneticCodesMapToGeneiousPrimeName
      .valueSource()
      .pipe(
        first(),
        tap((geneticCodes: SelectOption[]) =>
          restrictControlValue(
            this.form.controls.sequences_queryGeneticCode,
            geneticCodes.map((option) => option.value),
            { takeUntil: this.ngUnsubscribe, defaultValue: geneticCodes[0]?.value },
          ),
        ),
      );

    const formRawValue$ = this.form.valueChanges.pipe(
      startWith(null),
      map(() => this.form.getRawValue()),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );

    this.associatedNameSchemes$ = this.selectionState$.pipe(
      map((selection) => this.pipelineService.nameSchemesSetOnDocuments(selection)),
    );

    formRawValue$
      .pipe(
        map((formValues) => !!formValues.database_findAdditionalFeatures),
        distinctUntilChanged(),
      )
      .subscribe((result) => {
        if (result) {
          this.form.controls.database_featureDatabase.enable();
          this.form.controls.database_featureDatabaseMismatches.enable();
          this.form.controls.database_featureDatabaseGapSize.enable();
        } else {
          this.form.controls.database_featureDatabase.disable();
          this.form.controls.database_featureDatabaseMismatches.disable();
          this.form.controls.database_featureDatabaseGapSize.disable();
        }
      });

    formRawValue$
      .pipe(
        map((formValues) => formValues.genes_onlyAnnotateAminoAcidDifferences),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isOnlyAnnotateAminoAcidDifferences) => {
        if (isOnlyAnnotateAminoAcidDifferences === true) {
          this.form.controls.sequences_addNumberingAnnotations.disable();
        } else {
          this.form.controls.sequences_addNumberingAnnotations.enable();
        }
      });

    // Master Database form will produce all cluster tables, and thus the 'regionsSelector' needs to be removed regardless of whether it
    // is enabled or not.
    if (this.forMasterDatabase) {
      this.form.removeControl('regionsSelector');
    }

    this.form.setControl('results_clusterCombos', new FormControl(defaultClusterCombosGenes));

    this.twoHeavyChainsEnabled$ = this.featureSwitchService
      .isEnabledOnce('twoHeavyChainsInSameSequence')
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );

    this.showNGSRecommendation$ = combineLatest([
      this.selectionState$,
      this.featureSwitchService.isEnabledOnce('ngsAnnotator'),
    ]).pipe(
      map(
        ([state, isNGSAnnotatorEnabled]) =>
          isNGSAnnotatorEnabled &&
          state.selectedRows.reduce((acc, row) => acc + parseInt(row.number_of_sequences), 0) >
            1_000_000,
      ),
      startWith(false),
    );

    this.fullyAnnotatedTooltip$ = this.form.controls.sequences_chain.valueChanges.pipe(
      startWith(this.form.controls.sequences_chain.value as SequencesChain),
      map((chain: SequencesChain) => {
        switch (chain) {
          case 'singleUnknownChain':
          case 'lightChain':
          case 'heavyChain':
          case 'bothChainsAssociatedSequencesUnknownOrder':
            return '';
          case 'bothChainsNoLinker':
          case 'bothChainsWithLinker':
            return 'Choose a region in the first chain, then the second chain';
          case 'twoHeavyChainsNoLinker':
          case 'twoHeavyChainsWithLinker':
            return 'Choose a region in the Heavy-1 chain, then the Heavy-2 chain';
        }
      }),
    );

    // Async Validator needs to be set AFTER form creation due to a bug in Angular forms with Async Validators causing
    // form status to be stuck in PENDING.
    // Hopefully fixed soon:
    // https://github.com/angular/angular/pull/20806
    // https://github.com/angular/angular/pull/22575
    // https://github.com/angular/angular/issues/13200
    setTimeout(() => {
      this.form.controls.database_customDatabase.setAsyncValidators(
        this.validatorService.databaseValidator(),
      );
      this.form.controls.database_customDatabase.updateValueAndValidity();
      this.form.controls.database_featureDatabase.setAsyncValidators(
        this.validatorService.databaseValidator(),
      );
      this.form.controls.database_featureDatabase.updateValueAndValidity();

      // Set sequences chain value in case the async VHH options is delayed.
      const sequenceChainControl = this.form.controls.sequences_chain;
      sequenceChainControl.setValue(sequenceChainControl.value);

      currentValueAndChanges(this.form.controls.sequences_chain, this.ngUnsubscribe)
        .pipe(map((value) => value === 'bothChainsAssociatedSequencesUnknownOrder'))
        .subscribe((enableThreeOrMoreSequences) => {
          if (enableThreeOrMoreSequences) {
            this.form.controls.sequences_whenThreeOrMoreSequencesInGroup.enable();
          } else {
            this.form.controls.sequences_whenThreeOrMoreSequencesInGroup.disable();
          }
        });
    });
  }

  generateOutputFolderName(): string {
    return this.form.value.outputFolderName;
  }

  generateJobOptions(): NGSJobOptionsV1<AntibodyAnnotatorOptionValues> {
    const formValues: FormValues = {
      // form.value excludes disabled controls, so the return type has every property as optional
      // form.getRawValue() includes disabled controls, so it marks every property as required,
      // thus satisfying the FormValues type. This is a bit of a hack but the proper type-safe
      // solution would be to replicate all of disable/enable logic here explicitly.
      ...(this.form.value as FormRawValue<typeof this.form>),
      // Some settings can be disabled for interaction but we want to send their value anyway.
      fileNameSchemeID: this.form.controls.fileNameSchemeID.value,
      sequences_chain: this.form.controls.sequences_chain.value,
    };
    const optionValues = getPremiumOptions(formValues, this.isNucleotides, !this.forMasterDatabase);
    return {
      resultName: this.form.controls.resultName.value,
      optionValues,
    };
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  private initFormControls() {
    const requiredNumber = (initial: number, min: number, max: number) =>
      new BxFormControl(initial, [Validators.required, Validators.min(min), Validators.max(max)]);

    return new BxFormGroup({
      fileNameSchemeID: new BxFormControl<string>(undefined),
      resultName: JobDialogContent.getResultNameControl(),
      database_customDatabase: new BxFormControl<string[]>([], Validators.required),
      database_findAdditionalFeatures: new BxFormControl(false),
      database_featureDatabase: new BxFormControl<string>(undefined, Validators.required),
      database_featureDatabaseMismatches: requiredNumber(10, 0, 99),
      database_featureDatabaseGapSize: requiredNumber(3, 0, 99),
      database_linkerDatabase: new BxFormControl<string>(undefined),
      database_linkerDatabaseMismatches: new BxFormControl<number>(undefined, [
        Validators.min(0),
        Validators.max(60),
      ]),
      sequences_chain: new BxFormControl<SequencesChain>('singleUnknownChain', Validators.required),
      sequences_whenThreeOrMoreSequencesInGroup:
        new BxFormControl<ThreeOrMoreSequencesInGroupOptions>('leaveSequencesUnpaired'),
      genes_includePseudoGenes: new BxFormControl(true),
      genes_includeOrfGenes: new BxFormControl(false),
      genes_annotateGermlineGeneDifferences: new BxFormControl(false),
      genes_largeVGaps: new BxFormControl(false),
      forceFullRegionAnnotationOutsideCDR3: new BxFormControl(true),
      sequences_queryGeneticCode: new BxFormControl('universal', Validators.required),
      sequences_annotationStyle: new BxFormControl<SequencesAnnotationStyle>(
        'IMGT',
        Validators.required,
      ),
      genes_onlyAnnotateAminoAcidDifferences: new BxFormControl(false),
      sequences_addNumberingAnnotations: new BxFormControl(false),
      genes_ambiguousGenesStrategy: new BxFormControl<AmbiguousGenesStrategy>(
        'partialFrequency',
        Validators.required,
      ),
      results_calculateProteinStatistics: new BxFormControl(false),
      sequences_HeavyFR1Start: requiredNumber(0, -99, 99),
      sequences_HeavyFR1End: requiredNumber(0, -99, 99),
      sequences_HeavyFR2Start: requiredNumber(0, -99, 99),
      sequences_HeavyFR2End: requiredNumber(0, -99, 99),
      sequences_HeavyFR3Start: requiredNumber(0, -99, 99),
      sequences_HeavyFR3End: requiredNumber(0, -99, 99),
      sequences_HeavyFR4Start: requiredNumber(0, -99, 99),
      sequences_HeavyFR4End: requiredNumber(0, -99, 99),

      sequences_LightFR1Start: requiredNumber(0, -99, 99),
      sequences_LightFR1End: requiredNumber(0, -99, 99),
      sequences_LightFR2Start: requiredNumber(0, -99, 99),
      sequences_LightFR2End: requiredNumber(0, -99, 99),
      sequences_LightFR3Start: requiredNumber(0, -99, 99),
      sequences_LightFR3End: requiredNumber(0, -99, 99),
      sequences_LightFR4Start: requiredNumber(0, -99, 99),
      sequences_LightFR4End: requiredNumber(0, -99, 99),

      findLiabilities: new BxFormControl(false),
      liabilities_liabilitiesText: new BxFormControl(defaultLiabilities, Validators.required),

      // Note 'regionsSelector' value needs to be parsed into results_onlyClusterTablesFor before being sent to the
      // Antibody Annotator pipeline as parameters.
      regionsSelector: new BxFormControl(defaultRegionChips, Validators.required),

      results_clusterCombos: new BxFormControl(defaultClusterCombos, clusterComboValidator),

      results_trimSequences: new BxFormControl(false),
      results_trimSequencesLength: requiredNumber(10, 0, 9999),

      results_applySummaryScoreFilter: new BxFormControl(false),
      results_summaryScoreThreshold: requiredNumber(-1000, -99999, 99999),

      results_applySummaryFilter: new BxFormControl(false),
      results_summaryFilter: new BxFormControl('fullyAnnotated', Validators.required),

      results_fullyAnnotatedStart: new BxFormControl<AnnotatedGeneRange>(
        'FR1',
        Validators.required,
      ),
      results_fullyAnnotatedEnd: new BxFormControl<AnnotatedGeneRange>('FR4', Validators.required),
      outputFolderName: JobDialogContent.getResultNameControl(),
      customAdvancedOptions: new BxFormControl(''),
    });
  }

  /**
   * Handle toggling the availability (disable/enable) for each of these form controls.
   */
  private toggleControlsAvailability() {
    this.form.controls.sequences_chain.valueChanges
      .pipe(startWith(this.form.controls.sequences_chain.value), takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        if (value === 'bothChainsAssociatedSequencesUnknownOrder') {
          this.form.controls.sequences_whenThreeOrMoreSequencesInGroup.enable();
        } else {
          this.form.controls.sequences_whenThreeOrMoreSequencesInGroup.disable();
          this.form.controls.sequences_whenThreeOrMoreSequencesInGroup.reset(
            'leaveSequencesUnpaired',
          );
        }
      });
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.findLiabilities,
      [this.form.controls.liabilities_liabilitiesText],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_applySummaryScoreFilter,
      [this.form.controls.results_summaryScoreThreshold],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_applySummaryFilter,
      [this.form.controls.results_summaryFilter],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_trimSequences,
      [this.form.controls.results_trimSequencesLength],
      this.ngUnsubscribe,
    );
  }
}

/** Type of this.form.value */
export interface FormValues {
  fileNameSchemeID?: string;
  resultName?: string;
  database_customDatabase: string[];
  database_findAdditionalFeatures: boolean;
  database_featureDatabase: string;
  database_featureDatabaseMismatches: number;
  database_featureDatabaseGapSize: number;
  database_linkerDatabase: string;
  database_linkerDatabaseMismatches: number;
  sequences_chain: SequencesChain;
  genes_includePseudoGenes: boolean;
  genes_includeOrfGenes: boolean;
  genes_annotateGermlineGeneDifferences: boolean;
  genes_largeVGaps?: boolean;
  forceFullRegionAnnotationOutsideCDR3: boolean;
  sequences_queryGeneticCode: string;
  sequences_annotationStyle: SequencesAnnotationStyle;
  sequences_addNumberingAnnotations: boolean;
  genes_ambiguousGenesStrategy: 'partialFrequency' | 'groupOfGenes' | 'unknown';
  results_calculateProteinStatistics: boolean;
  sequences_HeavyFR1Start: number;
  sequences_HeavyFR1End: number;
  sequences_HeavyFR2Start: number;
  sequences_HeavyFR2End: number;
  sequences_HeavyFR3Start: number;
  sequences_HeavyFR3End: number;
  sequences_HeavyFR4Start: number;
  sequences_HeavyFR4End: number;
  sequences_LightFR1Start: number;
  sequences_LightFR1End: number;
  sequences_LightFR2Start: number;
  sequences_LightFR2End: number;
  sequences_LightFR3Start: number;
  sequences_LightFR3End: number;
  sequences_LightFR4Start: number;
  sequences_LightFR4End: number;
  findLiabilities: boolean;
  liabilities_liabilitiesText?: string;
  regionsSelector: (Chip | CombinationRegionChip)[];
  results_clusterCombos: string;
  results_trimSequences: boolean;
  results_trimSequencesLength?: number;
  results_applySummaryScoreFilter: boolean;
  results_summaryScoreThreshold?: number;
  results_applySummaryFilter: boolean;
  results_summaryFilter?: string;
  results_fullyAnnotatedStart: AnnotatedGeneRange;
  results_fullyAnnotatedEnd: AnnotatedGeneRange;
  outputFolderName?: string;
  onlyAnnotateAminoAcidDifferences?: boolean;
  customAdvancedOptions: string;
  [key: string]: unknown;
}

export type ThreeOrMoreSequencesInGroupOptions = 'leaveSequencesUnpaired' | 'createAllCombinations';
