import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import {
  AmbiguousGenesStrategy,
  annotationStyleOptions,
  defaultLiabilities,
  getOnlyClusterTablesFor,
  getSelectedChain,
  parseAndSetCustomAdvancedOptions,
  SequencesChain,
} from '../antibody-annotator/antibody-annotator-option-values.model';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  first,
  map,
  share,
  shareReplay,
  startWith,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { SelectOption } from '../../models/ui/select-option.model';
import { PipelineService } from '../../pipeline/pipeline.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { FeatureSwitchService } from 'src/app/features/feature-switch/feature-switch.service';
import { defaultRegionChips } from 'src/app/shared/regions-selector/regions-selector';
import { toggleControlsAvailabilityOnBoolean } from 'src/app/shared/form-helpers/toggle-controls-availability';
import { NgsAntibodyAnnotatorJobParametersV1 } from '../../../../nucleus/services/models/ngsAntibodyAnnotatorOptions.model';
import { SelectionState } from '../../../features/grid/grid.component';
import { RegionSelectorChain } from '../../../shared/regions-selector/regions-selector.component';
import {
  currentValueAndChanges,
  restrictControlValue,
  restrictControlValues,
} from 'src/app/shared/utils/forms';
import { DismissibleDirective } from '../../user-settings/dismissible/dismissible.directive';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AsyncPipe } from '@angular/common';
import { CardComponent } from '../../../shared/card/card.component';
import { MatIconModule } from '@angular/material/icon';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { SelectComponent } from '../../../shared/select/select.component';
import { ClusteringOptionsCardComponent } from '../shared/clustering-options-card/clustering-options-card.component';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { NameSchemeSelectComponent } from '../../name-schemes/name-scheme-select/name-scheme-select.component';
import { FRAdjustmentOptionsComponent } from '../shared/fr-adjustment-options/fr-adjustment-options.component';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';

@Component({
  selector: 'bx-ngs-antibody-annotator',
  templateUrl: './ngs-antibody-annotator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DismissibleDirective,
    NgbAlert,
    CardComponent,
    NgbTooltip,
    MatIconModule,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    FormErrorsComponent,
    CollapsibleCardComponent,
    SelectComponent,
    ClusteringOptionsCardComponent,
    ShowIfDirective,
    NameSchemeSelectComponent,
    FRAdjustmentOptionsComponent,
    PipelineOutputComponent,
    AsyncPipe,
  ],
})
export class NgsAntibodyAnnotatorComponent
  extends JobDialogContent
  implements OnInit, OnDestroy, RunnableJobDialog
{
  static title = 'NGS Antibody Annotator';

  readonly labelCDR = '15bp required for identifying CDR';
  readonly minBufferCDR = 15;
  faChevronRight = faChevronRight;

  earlyRelease = false;
  title = NgsAntibodyAnnotatorComponent.title;
  knowledgeBaseArticle: string;

  selected: SelectionState;
  selectedChain$: Observable<RegionSelectorChain>;
  geneticCodes$: Observable<SelectOption[]>;
  primerDatabases$: Observable<SelectOption[]>;
  referenceDatabases$: Observable<SelectOption[][]>;
  featureDatabases$: Observable<SelectOption[][]>;
  associatedNameSchemes: Set<string>;
  showLargeDataWarning: boolean;

  fullyAnnotatedStartCDR$: Observable<boolean>;
  fullyAnnotatedEndCDR$: Observable<boolean>;
  fullyAnnotatedTooltip$: Observable<string>;

  liabilitiesTextDisabled$: Observable<boolean>;
  twoHeavyChainsEnabled$: Observable<boolean>;
  multipleRefDbsEnabled$: Observable<boolean>;
  linkerDatabases$: Observable<SelectOption[]>;
  linkersEnabled$: Observable<boolean>;

  readonly annotationStyleOptions = annotationStyleOptions;
  private readonly defaultMaxLinkerMismatchPercentage = 20;
  readonly dismissAlert$: Subject<boolean> = new Subject();
  showUpstreamCDRMessage$: Observable<boolean>;
  showDownstreamCDRMessage$: Observable<boolean>;
  readonly form = this.initFormControls();

  private readonly formDefaults: any = this.form.getRawValue();
  private subscriptions = new Subscription();

  constructor(
    @Inject(PIPELINE_DIALOG_DATA) private dialogData: PipelineDialogData,
    private pipelineService: PipelineService,
    private validatorService: PipelineFormControlValidatorsService,
    private readonly featureSwitchService: FeatureSwitchService,
  ) {
    super('ngs-antibody-annotator', PipelineFormID.NGS_ANTIBODY_ANNOTATOR);

    this.linkersEnabled$ = this.featureSwitchService.isEnabledOnce('userSpecifiedLinkers');
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
    this.selected = this.dialogData.selected;
    this.primerDatabases$ = this.pipelineService.externalServices.primerSets.valueSource().pipe(
      first(),
      tap((databases: SelectOption[]) => {
        // If there is already an existing formValue set for primers controls,
        // then check if it exists in the available options, otherwise set the form control
        // to the first primers database.
        const customDatabaseFormValue = this.form.get('primers').value;
        if (
          !customDatabaseFormValue ||
          !databases.find((option) => option.value === customDatabaseFormValue)
        ) {
          this.form.controls.primers.setValue(databases[0]?.value);
        }

        this.formDefaults.primers = databases[0]?.value;
      }),
      share(),
    );
    this.subscriptions.add(
      this.form
        .get('trimPrimers')
        .valueChanges.pipe(
          // Delay operator needed since the PipelineDialogV2 Component disables the form and re-enables it and we want
          // to apply the disable/enable logic here after that.
          delay(0),
        )
        .subscribe((value) => {
          value ? this.form.get('primers').enable() : this.form.get('primers').disable();
        }),
    );
    // Async Validator needs to be set AFTER form creation due to a bug in Angular forms with Async Validators causing
    // form status to be stuck in PENDING.
    // Hopefully fixed soon:
    // https://github.com/angular/angular/pull/20806
    // https://github.com/angular/angular/pull/22575
    // https://github.com/angular/angular/issues/13200
    this.fullyAnnotatedStartCDR$ = this.form.controls.fullyAnnotatedStart.valueChanges.pipe(
      startWith(this.form.controls.fullyAnnotatedStart.value),
      map((value) => value.startsWith('CDR')),
      share(),
    );
    this.fullyAnnotatedEndCDR$ = this.form.controls.fullyAnnotatedEnd.valueChanges.pipe(
      startWith(this.form.controls.fullyAnnotatedEnd.value),
      map((value) => value.startsWith('CDR')),
      share(),
    );

    // The downstream length control is disabled/enabled depending on the retain downstream control.
    const downstreamControl = this.form.controls.retainDownstream;
    downstreamControl.valueChanges
      .pipe(
        delay(0),
        map((value) => !value || null),
        startWith(!downstreamControl.value),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isDownstreamUnChecked) => {
        if (isDownstreamUnChecked && !downstreamControl.disabled) {
          this.form.controls.retainDownstreamLength.disable();
        } else if (downstreamControl.disabled) {
          this.form.controls.retainDownstreamLength.enable();
          this.form.controls.retainDownstreamLength.setValidators([
            Validators.min(15),
            Validators.max(1000),
            Validators.required,
          ]);
        } else {
          this.form.controls.retainDownstreamLength.enable();
          this.form.controls.retainDownstreamLength.setValidators([
            Validators.min(0),
            Validators.max(1000),
            Validators.required,
          ]);
        }
        this.form.controls.retainDownstreamLength.updateValueAndValidity();
      });

    // The upstream length is disabled/enabled depending on the retain upstream control.
    const upstreamControl = this.form.controls.retainUpstream;
    upstreamControl.valueChanges
      .pipe(
        delay(0),
        map((value) => !value || null),
        startWith(!upstreamControl.value),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isUpstreamUnchecked) => {
        if (isUpstreamUnchecked && !upstreamControl.disabled) {
          this.form.controls.retainUpstreamLength.disable();
        } else if (upstreamControl.disabled) {
          this.form.controls.retainUpstreamLength.enable();
          this.form.controls.retainUpstreamLength.setValidators([
            Validators.min(15),
            Validators.max(1000),
            Validators.required,
          ]);
        } else {
          this.form.controls.retainUpstreamLength.enable();
          this.form.controls.retainUpstreamLength.setValidators([
            Validators.min(0),
            Validators.max(1000),
            Validators.required,
          ]);
        }
        this.form.controls.retainUpstreamLength.updateValueAndValidity();
      });

    const isBothChainsNoLinkerOppositeDirections$ = currentValueAndChanges(
      this.form.controls.chain,
      this.ngUnsubscribe,
    ).pipe(map((chain) => chain === 'bothChainsNoLinkerOppositeDirections'));
    combineLatest([
      this.fullyAnnotatedEndCDR$.pipe(map((fullyAnno) => fullyAnno || null)),
      isBothChainsNoLinkerOppositeDirections$,
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([isFullyAnno, isBothChainsNoLinkerOppositeDirections]) => {
        if (isFullyAnno && !isBothChainsNoLinkerOppositeDirections) {
          this.form.controls.retainDownstream.disable();
        } else {
          this.form.controls.retainDownstream.enable();
        }
      });

    combineLatest([
      this.fullyAnnotatedStartCDR$.pipe(map((fullyAnno) => fullyAnno || null)),
      isBothChainsNoLinkerOppositeDirections$,
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([isFullyAnno, isBothChainsNoLinkerOppositeDirections]) => {
        if (isFullyAnno && !isBothChainsNoLinkerOppositeDirections) {
          this.form.controls.retainUpstream.disable();
        } else {
          this.form.controls.retainUpstream.enable();
        }
      });

    this.showDownstreamCDRMessage$ = combineLatest([
      this.fullyAnnotatedEndCDR$,
      isBothChainsNoLinkerOppositeDirections$,
    ]).pipe(
      map(
        ([cdr, isBothChainsNoLinkerOppositeDirections]) =>
          cdr && !isBothChainsNoLinkerOppositeDirections,
      ),
    );

    this.showUpstreamCDRMessage$ = combineLatest([
      this.fullyAnnotatedStartCDR$,
      isBothChainsNoLinkerOppositeDirections$,
    ]).pipe(
      map(
        ([cdr, isBothChainsNoLinkerOppositeDirections]) =>
          cdr && !isBothChainsNoLinkerOppositeDirections,
      ),
    );

    // Selecting just a CDR as the fully annotated region requires at least 15 bases upstream and downstream. This is enforced in Prime
    // and mirrored here so the user isn't confused why there are upstream/downstream bases in their sequences.

    this.subscriptions.add(
      this.fullyAnnotatedStartCDR$.subscribe((selected) => {
        if (selected) {
          this.form.get('retainUpstream').setValue(true);
          if (this.form.get('retainUpstreamLength').value < this.minBufferCDR) {
            this.form.get('retainUpstreamLength').setValue(this.minBufferCDR);
          }
        }
      }),
    );

    this.subscriptions.add(
      this.fullyAnnotatedEndCDR$.subscribe((selected) => {
        if (selected) {
          this.form.get('retainDownstream').setValue(true);
          if (this.form.get('retainDownstreamLength').value < this.minBufferCDR) {
            this.form.get('retainDownstreamLength').setValue(this.minBufferCDR);
          }
        }
      }),
    );
  }

  ngOnInit() {
    this.selectedChain$ = this.form.controls.chain.valueChanges.pipe(
      startWith(this.form.controls.chain.value),
      map(getSelectedChain),
    );
    this.fullyAnnotatedTooltip$ = this.form.controls.chain.valueChanges.pipe(
      startWith(this.form.controls.chain.value as SequencesChain),
      map((chain: SequencesChain) => {
        switch (chain) {
          case 'singleUnknownChain':
          case 'lightChain':
          case 'heavyChain':
          case 'bothChainsAssociatedSequencesUnknownOrder':
            return '';
          case 'bothChainsNoLinker':
          case 'bothChainsWithLinker':
            return 'Choose a region in the first chain, then the second chain';
          case 'twoHeavyChainsNoLinker':
          case 'twoHeavyChainsWithLinker':
            return 'Choose a region in the Heavy-1 chain, then the Heavy-2 chain';
        }
      }),
    );
    this.twoHeavyChainsEnabled$ = this.featureSwitchService
      .isEnabledOnce('twoHeavyChainsInSameSequence')
      .pipe(
        shareReplay({
          bufferSize: 1,
          refCount: true,
        }),
      );
    toggleControlsAvailabilityOnBoolean(
      this.form.get('results_applySummaryScoreFilter') as FormControl,
      [this.form.get('results_summaryScoreThreshold') as FormControl],
      this.ngUnsubscribe,
    );

    this.referenceDatabases$ = this.pipelineService.getReferenceDatabases().pipe(
      first(),
      tap((options) => {
        const refDBControl = this.form.controls.antibodyDatabase;
        const databaseIDs = options.flatMap((group) => group.map((option) => option.value));
        restrictControlValues(refDBControl, databaseIDs, { takeUntil: this.ngUnsubscribe });
        if (databaseIDs[0] && !refDBControl.value?.length) {
          refDBControl.setValue([databaseIDs[0]]);
        }
      }),
      share(),
    );

    this.featureDatabases$ = this.pipelineService.getDatabases(DatabaseTypeEnum.FEATURE).pipe(
      first(),
      tap((options) => {
        const values = options.flatMap((group) => group.map((option) => option.value));
        return restrictControlValue(
          this.form.controls.antibodyAnnotator_database_featureDatabase,
          values,
          { takeUntil: this.ngUnsubscribe, defaultValue: values[0] },
        );
      }),
      share(),
    );

    this.geneticCodes$ = this.pipelineService.externalServices.geneticCodesMapToGeneiousPrimeName
      .valueSource()
      .pipe(
        first(),
        tap((geneticCodes: SelectOption[]) =>
          restrictControlValue(
            this.form.controls.antibodyAnnotator_sequences_queryGeneticCode,
            geneticCodes.map((option) => option.value),
            { takeUntil: this.ngUnsubscribe, defaultValue: geneticCodes[0]?.value },
          ),
        ),
        share(),
      );

    this.associatedNameSchemes = this.pipelineService.nameSchemesSetOnDocuments(this.selected);

    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        map((formValues) => formValues.antibodyAnnotator_genes_onlyAnnotateAminoAcidDifferences),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((isOnlyAnnotateAminoAcidDifferences) => {
        if (isOnlyAnnotateAminoAcidDifferences === true) {
          this.form.controls.antibodyAnnotator_sequences_addNumberingAnnotations.disable();
        } else {
          this.form.controls.antibodyAnnotator_sequences_addNumberingAnnotations.enable();
        }
      });

    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        map((formValues) => !!formValues.antibodyAnnotator_database_findAdditionalFeatures),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((result) => {
        if (result) {
          this.form.controls.antibodyAnnotator_database_featureDatabase.enable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseMismatches.enable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseGapSize.enable();
        } else {
          this.form.controls.antibodyAnnotator_database_featureDatabase.disable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseMismatches.disable();
          this.form.controls.antibodyAnnotator_database_featureDatabaseGapSize.disable();
        }
      });

    this.selectedChain$
      .pipe(
        map((chain) => chain == 'bothWithLinker' || chain == 'twoHeavyWithLinker'),
        distinctUntilChanged(),
      )
      .subscribe((result) => {
        if (result) {
          this.form.controls.antibodyAnnotator_database_linkerDatabase.enable();
        } else {
          this.form.controls.antibodyAnnotator_database_linkerDatabase.reset();
          this.form.controls.antibodyAnnotator_database_linkerDatabase.disable();
        }
      });

    this.form.valueChanges
      .pipe(
        startWith(this.form.getRawValue()),
        map((formValues) => formValues.antibodyAnnotator_database_linkerDatabase),
        distinctUntilChanged(),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe((linkerDatabase) => {
        if (linkerDatabase) {
          this.form.controls.antibodyAnnotator_database_linkerDatabaseMismatches.enable();
          if (
            this.form.controls.antibodyAnnotator_database_linkerDatabaseMismatches.value == null
          ) {
            this.form.controls.antibodyAnnotator_database_linkerDatabaseMismatches.setValue(
              this.defaultMaxLinkerMismatchPercentage,
            );
          }
        } else {
          this.form.controls.antibodyAnnotator_database_linkerDatabaseMismatches.reset();
          this.form.controls.antibodyAnnotator_database_linkerDatabaseMismatches.disable();
        }
      });

    this.linkerDatabases$ = this.pipelineService.getDatabases(DatabaseTypeEnum.LINKER).pipe(
      first(),
      map((databases) => databases.flat(1)),
      share(),
    );

    this.liabilitiesTextDisabled$ = this.form.controls.includeLiabilities.valueChanges.pipe(
      startWith(this.form.controls.includeLiabilities.value),
      map((value) => !value || null),
    );

    setTimeout(() => {
      this.form.get('primers').setAsyncValidators(this.validatorService.databaseValidator());
      this.form.get('primers').updateValueAndValidity();
      this.form
        .get('antibodyDatabase')
        .setAsyncValidators(this.validatorService.databaseValidator());

      this.form.get('antibodyDatabase').updateValueAndValidity();
      this.form.get('antibodyAnnotator_database_featureDatabase').updateValueAndValidity();
      // Set sequences chain value in case the async VHH options is delayed.
      const sequenceChainControl = this.form.controls.chain;
      sequenceChainControl.setValue(sequenceChainControl.value);
    });

    const numberOfSelectedSequences = this.selected.selectedRows.reduce(
      (acc, row) => acc + parseInt((row.number_of_sequences as string) ?? '1'),
      0,
    );
    this.showLargeDataWarning = numberOfSelectedSequences > 100_000_000;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriptions.unsubscribe();
  }

  run() {
    const formValue = this.form.value as typeof this.form.value &
      Record<string, boolean | number | string>;

    // Retain upstream/downstream tickboxes can have true values but disabled to prevent user interaction. Explicitly send their
    // values as disabling stops them being sent by default.
    formValue.retainUpstream = this.form.controls.retainUpstream.value;
    formValue.retainDownstream = this.form.controls.retainDownstream.value;
    formValue.antibodyAnnotator_genes_truncatedVJMismatchesOutsideCDR3 = true;
    formValue.antibodyAnnotator_results_applyOnlyClusterTablesFor = true;
    formValue.antibodyAnnotator_results_onlyClusterTablesFor = getOnlyClusterTablesFor(
      this.form.get('regionsSelector').value,
    );
    formValue.antibodyAnnotator_results_useBeforeCollapsingFrequencies = true;
    formValue.antibodyAnnotator_results_applySummaryScoreFilter = this.form.get(
      'results_applySummaryScoreFilter',
    ).value;
    formValue.antibodyAnnotator_results_summaryScoreThreshold = this.form.get(
      'results_summaryScoreThreshold',
    ).value;
    formValue.minimumSignificantOfCellPercent = 0;
    formValue.minimumSignificantReadCount = 1;
    formValue.minimumVariantSupport = 0;
    formValue.strictMinimumVariantSupport = 0;
    formValue.minLength = 1;
    formValue.isNGSAnnotator = true;

    delete formValue.results_applySummaryScoreFilter;
    delete formValue.results_summaryScoreThreshold;
    delete formValue.regionsSelector;

    const options = {
      optionValues: formValue,
      resultName: this.form.get('resultName').value,
    };

    // Remove pipeline options from the GaaL options list.
    delete options.optionValues.resultName;
    delete options.optionValues.forceFullRegionAnnotationOutsideCDR3;
    delete options.optionValues.outputFolderName;
    parseAndSetCustomAdvancedOptions(options.optionValues);
    const parameters = {
      options,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: this.form.get('outputFolderName').value,
      },
    };

    return new NgsAntibodyAnnotatorJobParametersV1(
      parameters.selection,
      parameters.options,
      parameters.output,
    );
  }

  getFormDefaults(): any {
    return this.formDefaults;
  }

  private initFormControls() {
    const requiredNumber = (initial: number, min: number, max: number) =>
      new BxFormControl(initial, [Validators.required, Validators.min(min), Validators.max(max)]);
    return new BxFormGroup({
      chain: new BxFormControl<SequencesChain>('singleUnknownChain', Validators.required),
      antibodyAnnotator_liabilities_liabilitiesText: new BxFormControl(
        defaultLiabilities,
        Validators.required,
      ),
      antibodyAnnotator_genes_onlyAnnotateAminoAcidDifferences: new BxFormControl(false),
      antibodyAnnotator_genes_includePseudoGenes: new BxFormControl(true),
      antibodyAnnotator_genes_includeOrfGenes: new BxFormControl(false),
      antibodyAnnotator_sequences_annotationStyle: new BxFormControl('IMGT', Validators.required),
      antibodyAnnotator_sequences_addNumberingAnnotations: new BxFormControl(false),
      antibodyAnnotator_sequences_queryGeneticCode: new BxFormControl(
        'universal',
        Validators.required,
      ),
      antibodyAnnotator_database_findAdditionalFeatures: new BxFormControl(false),
      antibodyAnnotator_database_featureDatabase: new BxFormControl<string>(
        undefined,
        Validators.required,
      ),
      antibodyAnnotator_database_linkerDatabase: new BxFormControl<string>(undefined),
      antibodyAnnotator_database_linkerDatabaseMismatches: new BxFormControl<number>(undefined, [
        Validators.min(0),
        Validators.max(60),
      ]),
      antibodyAnnotator_database_featureDatabaseMismatches: requiredNumber(10, 0, 99),
      antibodyAnnotator_database_featureDatabaseGapSize: requiredNumber(3, 0, 99),
      antibodyAnnotator_genes_ambiguousGenesStrategy: new BxFormControl<AmbiguousGenesStrategy>(
        'partialFrequency',
        Validators.required,
      ),
      calculateProteinStatistics: new BxFormControl(false),
      resultName: JobDialogContent.getResultNameControl(),
      fileNameSchemeID: new BxFormControl(null),
      trimPrimers: new BxFormControl(false),
      primers: new BxFormControl(null, Validators.required),
      keepUnmerged: new BxFormControl(false),
      antibodyDatabase: new BxFormControl([], Validators.required),
      deNovoAssemblyRequired: new BxFormControl(false),
      clusterVDJ: new BxFormControl(true),
      clusterVDJthresholdPercent: new BxFormControl(100, [Validators.min(0), Validators.max(100)]),
      minimumVariantSupport: new BxFormControl(20, [Validators.min(0), Validators.max(100)]),
      fullyAnnotatedStart: new BxFormControl('FR1', Validators.required),
      fullyAnnotatedEnd: new BxFormControl('FR4', Validators.required),
      retainUpstream: new BxFormControl(false),
      retainUpstreamLength: new BxFormControl(10, [Validators.min(0), Validators.max(1000)]),
      retainDownstream: new BxFormControl(false),
      retainDownstreamLength: new BxFormControl(30, [Validators.min(0), Validators.max(1000)]),
      includeLiabilities: new BxFormControl(false),
      includeGermlines: new BxFormControl(true),
      associateDominantSignificantHeavyLightChains: new BxFormControl(false),
      regionsSelector: new BxFormControl(defaultRegionChips, Validators.required),
      results_applySummaryScoreFilter: new BxFormControl(false),
      results_summaryScoreThreshold: new BxFormControl(-1000, [
        Validators.required,
        Validators.min(-99999),
        Validators.max(99999),
      ]),
      antibodyAnnotator_sequences_HeavyFR1Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR1End: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR2Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR2End: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR3Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR3End: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR4Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_HeavyFR4End: requiredNumber(0, -99, 99),

      antibodyAnnotator_sequences_LightFR1Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_LightFR1End: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_LightFR2Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_LightFR2End: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_LightFR3Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_LightFR3End: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_LightFR4Start: requiredNumber(0, -99, 99),
      antibodyAnnotator_sequences_LightFR4End: requiredNumber(0, -99, 99),
      customAdvancedOptions: new BxFormControl(''),
      outputFolderName: JobDialogContent.getResultNameControl(),
    });
  }
}
