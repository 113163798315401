// These are used as the default cluster combos when the 'clusteringSelection' feature switch is
// enabled. This will produce the related heatmaps for these combination clusters but NOT their
// corresponding tables. 'Genes Combination' graph is reliant on these being set in
// 'results_clusterCombos'.
import {
  CombinationRegionChip,
  isCombinationRegionChip,
  RegionSelectorChain,
} from '../../../shared/regions-selector/regions-selector.component';
import { FormValues as PremiumAnnotatorFormValues } from './antibody-annotator.component';
import { FormValues as FreeAnnotatorFormValues } from './antibody-annotator-free.component';
import { Chip } from 'src/app/shared/chips';
import { AnnotatorMethod } from '../../../../nucleus/services/models/peptideAnnotatorOptions.model';

export const defaultClusterCombosGenes =
  'Heavy-Light V Gene: Heavy V Gene, Light V Gene\n' +
  'Heavy-Light J Gene: Heavy J Gene, Light J Gene\n' +
  'Heavy VJ Gene: Heavy V Gene, Heavy J Gene\n' +
  'Light VJ Gene: Light V Gene, Light J Gene';

export const defaultClusterCombos =
  'Heavy-Light CDR3: Heavy CDR3, Light CDR3\n' + defaultClusterCombosGenes;

/** Static GAAL options */
export const gaalOptions = {
  identifyGenes: 'true',
  results_storeSummaryLinks: 'true',
  database_databaseType: 'customDatabase',
  sequences_analyseSequenceListsIndependently: 'false',
  results_createOneRowPerSequenceTable: 'true',
  results_storeLimitedInformationForAllClustersForLaterComparison: 'true',
  results_maxSummaryRows: '1000',
} as const;

export const dnaOnlyOptions = [
  'database_findAdditionalFeatures',
  'database_featureDatabase',
  'database_featureDatabaseMismatches',
  'database_featureDatabaseGapSize',
  'genes_largeVGaps',
  'genes_truncatedVJMismatchesOutsideCDR3',
  'results_trimSequences',
  'results_trimSequencesLength',
] as const;

export type AnnotatedGeneRange =
  | 'FR1'
  | 'CDR1'
  | 'FR2'
  | 'CDR2'
  | 'FR3'
  | 'CDR3'
  | 'FR4'
  | 'Constant Region';

/**
 * This can be used to identify which antibody annotator type the document is from. Eg 'genericSequence' indicates the
 * document is a protein/peptide result.
 */
export type SequencesChain =
  | 'singleUnknownChain'
  | 'lightChain'
  | 'heavyChain'
  | 'bothChainsNoLinker'
  | 'bothChainsNoLinkerOppositeDirections'
  | 'bothChainsWithLinker'
  | 'bothChainsAssociatedSequencesUnknownOrder'
  | 'twoHeavyChainsNoLinker'
  | 'twoHeavyChainsWithLinker'
  | 'genericSequence';

export const SequencesAnnotationStyles = [
  'IMGT',
  'Kabat',
  'Chothia',
  'Martin',
  'AHo',
  'From Database',
] as const;
export type SequencesAnnotationStyle = (typeof SequencesAnnotationStyles)[number];

export type AmbiguousGenesStrategy = 'partialFrequency' | 'groupOfGenes' | 'unknown';

/**
 * Options for sequences_annotationStyle to display in the UI.
 */
export const annotationStyleOptions: { label: string; value: SequencesAnnotationStyle }[] = [
  { label: 'IMGT', value: 'IMGT' },
  { label: 'Kabat', value: 'Kabat' },
  { label: 'Chothia', value: 'Chothia' },
  { label: 'Martin', value: 'Martin' },
  { label: 'AHo (Kabat CDR definitions)', value: 'AHo' },
  { label: 'From Database', value: 'From Database' },
];

type GAALOptionValues = typeof gaalOptions;
export interface AntibodyAnnotatorOptionValues extends GAALOptionValues {
  // NOTE. Most of these options are marked as optional, because they aren't required as inputs by antibody annotator.
  // If an option is not provided, then the default option (defined within the prime plugin) will be used.

  database_customDatabase?: string[];
  fileNameSchemeID?: string;
  findLiabilities?: boolean;
  genes_ambiguousGenesStrategy?: 'partialFrequency' | 'groupOfGenes' | 'unknown';
  genes_annotateGermlineGeneDifferences?: boolean;
  genes_includeOrfGenes?: boolean;
  genes_includePseudoGenes?: boolean;
  genes_onlyAnnotateAminoAcidDifferences?: boolean;
  results_applyOnlyClusterTablesFor?: boolean;
  results_applySummaryFilter?: boolean;
  results_applySummaryScoreFilter?: boolean;
  results_calculateProteinStatistics?: boolean;
  results_clusterCombos?: string;
  results_fullyAnnotatedEnd?: AnnotatedGeneRange;
  results_fullyAnnotatedStart?: AnnotatedGeneRange;
  results_onlyClusterTablesFor?: string;
  results_summaryScoreThreshold?: number;
  sequences_annotationStyle?: SequencesAnnotationStyle;
  sequences_chain?: SequencesChain;
  sequences_queryGeneticCode?: string;

  sequences_HeavyFR1Start?: number;
  sequences_HeavyFR1End?: number;
  sequences_HeavyFR2Start?: number;
  sequences_HeavyFR2End?: number;
  sequences_HeavyFR3Start?: number;
  sequences_HeavyFR3End?: number;
  sequences_HeavyFR4Start?: number;
  sequences_HeavyFR4End?: number;
  sequences_LightFR1Start?: number;
  sequences_LightFR1End?: number;
  sequences_LightFR2Start?: number;
  sequences_LightFR2End?: number;
  sequences_LightFR3Start?: number;
  sequences_LightFR3End?: number;
  sequences_LightFR4Start?: number;
  sequences_LightFR4End?: number;
  sequences_addNumberingAnnotations?: boolean;

  // DNA-only values
  genes_largeVGaps?: boolean;
  database_findAdditionalFeatures?: boolean;
  genes_truncatedVJMismatchesOutsideCDR3?: boolean;
  results_trimSequences?: boolean;

  // Deprecated options but might still be stored in older job params
  clusterMethod?: string;
  sequenceIdentityThreshold?: string;
  region?: string;
}

type OptionValuesWithCustomAdvancedOptions = {
  customAdvancedOptions: string;
  [key: string]: unknown;
};

export interface SingleCellOnlyOptionValues {
  calculateProteinStatistics?: boolean;
  annotatorMethod?: AnnotatorMethod;
  fileNameSchemeID?: string;
  trimPrimers?: boolean;
  primers?: string[];
  keepUnmerged?: boolean;
  minLength?: number;
  onlyUseLongest?: boolean;
  onlyUseLongestLength?: number;
  antibodyDatabase?: string[];
  deNovoAssemblyRequired?: boolean;
  clusterVDJ?: boolean;
  clusterVDJthresholdPercent?: number;
  minimumSignificantOfCellPercent?: number;
  minimumSignificantReadCount?: number;
  minimumVariantSupport?: number;
  strictMinimumVariantSupport?: number;
  fullyAnnotatedStart?: string;
  fullyAnnotatedEnd?: string;
  retainUpstream?: boolean;
  retainUpstreamLength?: number;
  retainDownstream?: boolean;
  retainDownstreamLength?: number;
  includeLiabilities?: boolean;
  includeGermlines?: boolean;
  associateDominantSignificantHeavyLightChains?: boolean;
  forceFullRegionAnnotationOutsideCDR3?: boolean;
}

export type SingleCellAntibodyAnnotatorOptions = {
  [key in keyof AntibodyAnnotatorOptionValues as `antibodyAnnotator_${key}`]?: AntibodyAnnotatorOptionValues[key];
};
export type SingleCellOptionValues = SingleCellOnlyOptionValues &
  SingleCellAntibodyAnnotatorOptions;

/**
 * Given that optionValues contains a String field called customAdvancedOptions, parses and
 * splits the text into the specified options, then deletes the customAdvancedOptions value.
 * e.g. if
 * optionValues.customAdvancedOptions="option1=value1 option2=value\s2"
 * then optionValues.customAdvancedOptions will be deleted and the following values set:
 * optionValues.option1="value1"
 * optionValues.option2="value 2"
 */
export function parseAndSetCustomAdvancedOptions(
  optionValues: Partial<OptionValuesWithCustomAdvancedOptions>,
) {
  const customAdvancedOptionsValue = (optionValues.customAdvancedOptions as string) ?? '';
  const allOptions = customAdvancedOptionsValue.split(' ');
  for (const option of allOptions) {
    const nameAndValue = option.split('=');
    if (nameAndValue.length < 2) continue;
    const optionName = unescapeCustomOption(nameAndValue[0]);
    const optionValue = unescapeCustomOption(nameAndValue[1]);
    optionValues[optionName] = optionValue;
  }
  delete optionValues.customAdvancedOptions;
  return optionValues;
}

/**
 * Unescapes a custom option string, by replacing
 * "\s" with " "
 * "\e" with "="
 * "\\" with "\"
 */
export function unescapeCustomOption(value: string) {
  let result = '';
  for (let i = 0; i < value.length; i++) {
    let c = value[i];
    if (c == '\\' && i + 1 < value.length) {
      let c2 = value[++i];
      if (c2 == 's') {
        c2 = ' ';
      }
      if (c2 == 'e') {
        c2 = '=';
      }
      result = result + c2;
    } else {
      result = result + c;
    }
  }
  return result;
}

export function getSelectedChain(sequencesChain: SequencesChain): RegionSelectorChain {
  switch (sequencesChain) {
    case 'singleUnknownChain':
      return 'bothNoLinker';
    case 'heavyChain':
      return 'heavy';
    case 'lightChain':
      return 'light';
    case 'bothChainsNoLinker':
      return 'bothNoLinker';
    case 'bothChainsNoLinkerOppositeDirections':
      return 'bothNoLinker';
    case 'bothChainsWithLinker':
      return 'bothWithLinker';
    case 'bothChainsAssociatedSequencesUnknownOrder':
      return 'bothNoLinker';
    case 'twoHeavyChainsNoLinker':
      return 'twoHeavyNoLinker';
    case 'twoHeavyChainsWithLinker':
      return 'twoHeavyWithLinker';
    case 'genericSequence':
      return 'genericSequence';
  }
}

export function getOnlyClusterTablesFor(regionsSelector: (Chip | CombinationRegionChip)[]): string {
  return regionsSelector
    .map((chip) => {
      if (isCombinationRegionChip(chip)) {
        return `${chip.id}: ${chip.regions.join(', ')}`;
      } else {
        return chip.id;
      }
    })
    .join('\n');
}

export const getPremiumOptions = (
  formValues: PremiumAnnotatorFormValues,
  isNucleotides: boolean,
  clusteringSelectionEnabled: boolean,
): AntibodyAnnotatorOptionValues => {
  const optionValues: AntibodyAnnotatorOptionValues & PremiumAnnotatorFormValues = {
    ...formValues,
    ...gaalOptions,
    genes_truncatedVJMismatchesOutsideCDR3: !formValues.forceFullRegionAnnotationOutsideCDR3,
    results_applyOnlyClusterTablesFor: true,
  };

  if (clusteringSelectionEnabled) {
    optionValues.results_onlyClusterTablesFor = getOnlyClusterTablesFor(formValues.regionsSelector);
  }

  // Remove form keys which aren't option values
  delete optionValues.forceFullRegionAnnotationOutsideCDR3;
  delete optionValues.resultName;
  delete optionValues.regionsSelector;
  delete optionValues.outputFolderName;
  // Filter out some DNA-only options if the current data includes protein sequences.
  if (!isNucleotides) {
    dnaOnlyOptions.forEach((option) => delete optionValues[option]);
  }
  parseAndSetCustomAdvancedOptions(optionValues);
  return optionValues;
};

export const getFreeOptions = (
  formValues: FreeAnnotatorFormValues,
): AntibodyAnnotatorOptionValues => {
  delete formValues.resultName;
  delete formValues.outputFolderName;
  return {
    ...formValues,
    ...gaalOptions,
    fileNameSchemeID: null,
    genes_includePseudoGenes: true,
    genes_includeOrfGenes: true,
    genes_annotateGermlineGeneDifferences: true,
    sequences_queryGeneticCode: 'universal',
    genes_ambiguousGenesStrategy: 'partialFrequency',
    results_calculateProteinStatistics: true,
    sequences_addNumberingAnnotations: false,
    sequences_HeavyFR1Start: 0,
    sequences_HeavyFR1End: 0,
    sequences_HeavyFR2Start: 0,
    sequences_HeavyFR2End: 0,
    sequences_HeavyFR3Start: 0,
    sequences_HeavyFR3End: 0,
    sequences_HeavyFR4Start: 0,
    sequences_HeavyFR4End: 0,
    sequences_LightFR1Start: 0,
    sequences_LightFR1End: 0,
    sequences_LightFR2Start: 0,
    sequences_LightFR2End: 0,
    sequences_LightFR3Start: 0,
    sequences_LightFR3End: 0,
    sequences_LightFR4Start: 0,
    sequences_LightFR4End: 0,
    findLiabilities: false,
    // This will produce the cluster combos genes as data for Graphs ONLY.
    // It won't produce extra tables if the results_applyOnlyClusterTablesFor is set to true.
    results_clusterCombos: defaultClusterCombosGenes,
    results_applySummaryScoreFilter: false,
    results_applySummaryFilter: false,
    results_fullyAnnotatedStart: 'FR1',
    results_fullyAnnotatedEnd: 'FR4',
    results_applyOnlyClusterTablesFor: true,
    results_onlyClusterTablesFor: 'Heavy V Gene\nHeavy J Gene\nLight V Gene\nLight J Gene',
  };
};

export const defaultPAndPLiabilities = `-1000 Error:
  Stop_Codon_(Amber) .TAG (In_Frame)
  Stop_Codon_(Ochre) .TAA (In_Frame)
  Stop_Codon_(Opal) .TGA (In_Frame)
  Frame_Shift
  Not_Fully_Annotated
  Contamination +R +S +Y +W +K +M +B +D +H +V "Base Quality >= 20" (Template_Region)
  Likely_Sequencing_Error "Expected Sequencing Errors > 0.75" (Template_Region)

-100 Liability (High):
  Possible_Sequencing_Error "Expected Sequencing Errors > 0.2" (Template_Region)
  Low_Quality_Base "Base Quality < 10" (Template_Region)
  Deamidation NG NS NA
  Isomerization DG DS
  Cleavage DP
  Glycosylation N{P}S{P} N{P}T{P}

-10 Liability (Medium):
  Deamidation NH
  Hydrolysis NP
  Cleavage TS

-1 Liability (Low):
  Deamidation SN TN KN
  Deamidation NY
  Oxidation M

+1 Asset:
  6His .CATCATCATCACCATCAC
  Myc .GAACAAAAACTCATCTCAGAAGAGGATCTG
  M13 .TTAGTTGTTCCTTTCTATTCTCACAGT
  Pel_B .TTACTCGCGGCCCAGCCGGCCATGGCC
`;

export const defaultLiabilities = `-1000 Error:
  Stop_Codon_(Amber) .TAG (In_Frame)
  Stop_Codon_(Ochre) .TAA (In_Frame)
  Stop_Codon_(Opal) .TGA (In_Frame)
  Frame_Shift
  Not_Fully_Annotated
  Contamination +R +S +Y +W +K +M +B +D +H +V "Base Quality >= 20" (VDJ-REGION VJ-REGION VDJC-REGION VJC-REGION)
  Likely_Sequencing_Error "Expected Sequencing Errors > 0.75" (VDJ-REGION VJ-REGION)

-100 Liability (High):
  Possible_Sequencing_Error "Expected Sequencing Errors > 0.2" (VDJ-REGION VJ-REGION)
  Low_Quality_Base "Base Quality < 10" (VDJ-REGION VJ-REGION)
  Deamidation NG NS NA (Heavy_CDR2 Light_CDR1)
  Isomerization DG DS (Heavy_CDR2 Heavy_CDR3 Light_CDR1)
  Cleavage DP
  Oxidation M (CDR1 CDR2 CDR3)
  Missing_Cysteine C<1 (FR1 FR3)
  Extra_Cysteine C>1 (FR1 FR3)
  Extra_Cysteine C>0 (FR2 FR4 CDR1 CDR2 CDR3)
  Glycosylation N{P}S{P} N{P}T{P}

-10 Liability (Medium):
  Deamidation NH
  Hydrolysis NP
  Cleavage TS
  Hydrophobic_Pocket VVV WWW
  Hydrophilic_Pocket YY (Heavy_CDR3)

-1 Liability (Low):
  Deamidation SN TN KN
  Deamidation NY (Heavy_CDR2)

+1 Asset:
  6His .CATCATCATCACCATCAC
  Myc .GAACAAAAACTCATCTCAGAAGAGGATCTG
  M13 .TTAGTTGTTCCTTTCTATTCTCACAGT
  Pel_B .TTACTCGCGGCCCAGCCGGCCATGGCC

-10 Liability (Medium):
  # Positional residues heavy or light chain:
  Position ![S T] !position_found (IMGT-22 AHo-22 H-KabatBased-21 L-KabatBased-22)
  Position !C !position_found (IMGT-23 AHo-23 H-KabatBased-22 L-KabatBased-23)
  Position !W !position_found (IMGT-41 AHo-43 H-KabatBased-36 L-KabatBased-35)
  Position !C !position_found (IMGT-104 AHo-106 H-KabatBased-92 L-KabatBased-88)
  Position !G !position_found (IMGT-119 AHo-140 H-KabatBased-104 L-KabatBased-99)
  Position !G !position_found (IMGT-121 AHo-142 H-KabatBased-106 L-KabatBased-101)

  # Positional residues light chain only:
  Position ![Q L] !position_found (L-IMGT-43 L-AHo-45 L-KabatBased-37)
  Position !G !position_found (L-IMGT-70 L-AHo-73 L-KabatBased-57)
  Position ![V I] !position_found (L-IMGT-71 L-AHo-74 L-KabatBased-58)
  Position ![P S] !position_found (L-IMGT-72 L-AHo-75 L-KabatBased-59)
  Position !F !position_found (L-IMGT-118 L-AHo-139 L-KabatBased-98)

  # Positional residues heavy chain only:
  Position !G !position_found (H-IMGT-27 H-AHo-27 H-KabatBased-26)
  Position ![Y F G] !position_found (H-IMGT-28 H-AHo-29 H-KabatBased-27)
  Position ![V I A] !position_found (H-IMGT-42 H-AHo-44 H-KabatBased-37)
  Position ![R K] !position_found (H-IMGT-43 H-AHo-45 H-KabatBased-38)
  Position !Q !position_found (H-IMGT-44 H-AHo-46 H-KabatBased-39)
  Position ![G A S] !position_found (H-IMGT-54 H-AHo-56 H-KabatBased-49)
  Position ![L I V F T A] !position_found (H-IMGT-76 H-AHo-78 H-KabatBased-67)
  Position ![T S I A] !position_found (H-IMGT-77 H-AHo-79 H-KabatBased-68)
  Position !W !position_found (H-IMGT-118 H-AHo-139 H-KabatBased-103)
  Position !Q !position_found (H-IMGT-120 H-AHo-141 H-KabatBased-105)

-1 Liability (Low):
  # Positional residues light chain only:
  Position ![Y L F] !position_found (L-IMGT-42 L-AHo-44 L-KabatBased-36)
  Position ![I V] !position_found (L-IMGT-54 L-AHo-56 L-KabatBased-48)
  Position ![Y K F] !position_found (L-IMGT-55 L-AHo-57 L-KabatBased-49)

  # Positional residues heavy chain only:
  Position !S !position_found (H-IMGT-26 H-AHo-26 H-KabatBased-25)
  Position !W !position_found (H-IMGT-52 H-AHo-54 H-KabatBased-47)
  Position ![R K] !position_found (H-IMGT-75 H-AHo-77 H-KabatBased-66)
  Position !A !position_found (H-IMGT-105 H-AHo-107 H-KabatBased-93)
  Position !R !position_found (H-IMGT-106 H-AHo-108 H-KabatBased-94)
`;
