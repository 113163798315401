import { ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild } from '@angular/core';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core.store';
import { selectDataWithSelectionForNgsDocument } from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { getTotalColumn } from '../../../../../features/graphs/graph-document-data.service';
import { GraphControlTypeEnum } from '../../../../../features/graphs/graph-sidebar';
import { AlignmentType, alignmentTypes, GraphDataFor } from '../../ngs-graphs.model';
import {
  GraphNetworkComponent,
  LinkType,
  NetworkData,
  NetworkGraphNodeConfig,
  NodeType,
} from '../../../../../features/graphs/graph-network/graph-network.component';
import { ColorPaletteID } from '../../../../color/color-palette.model';
import { TreeGraphMetadataMap } from '../../../../../features/graphs/graph-circular-tree/graph-circular-tree.model';
import { TreeMetadataExtractor } from '../../../../../features/graphs/datasources/tree-metadata-extractor';
import { SelectGroup, SelectOption } from '../../../../models/ui/select-option.model';
import { generateSpanningTree } from './spanning-tree/spanning-tree';
import { graphSelectionUnchanged } from '../../ngs-graph-data-store/ngs-graph-data-store.reducer';
import { FormControl, FormsModule } from '@angular/forms';
import { AsyncPipe } from '@angular/common';
import { CleanUp } from '../../../../../shared/cleanup';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { GraphSidebarOptionsService } from '../../../../user-settings/graph-sidebar-options/graph-sidebar-options.service';

class LayoutOptions {
  constructor(
    public resetLayout: Function,
    public freezeLayout$: BehaviorSubject<boolean>,
    public form: FormControl,
  ) {}
}

@Component({
  selector: 'bx-ngs-network-layout-options',
  templateUrl: './ngs-network-layout-options.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [FormsModule, AsyncPipe, NgbTooltip],
})
class NetworkLayoutOptionsComponent extends CleanUp {
  constructor(public options: LayoutOptions) {
    super();
    this.options.freezeLayout$
      .pipe(distinctUntilChanged(), takeUntil(this.ngUnsubscribe))
      .subscribe((freeze) => {
        this.options.form.setValue(freeze);
      });
  }
}

@Component({
  selector: 'bx-ngs-cluster-network',
  templateUrl: './ngs-cluster-network.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GraphNetworkComponent, AsyncPipe],
})
export class NgsClusterNetworkComponent
  extends NgsBaseGraphComponent<NetworkData, GraphNetworkComponent>
  implements OnInit
{
  @ViewChild(GraphNetworkComponent) chartComponent: GraphNetworkComponent;

  title$: Observable<string>;

  freezeLayoutControl$ = this.completeOnDestroy(new BehaviorSubject<boolean>(true));
  freezeLayout$: Observable<boolean>;

  colourNodesControl$ = this.completeOnDestroy(new BehaviorSubject<boolean>(true));
  colourNodes$: Observable<boolean>;

  nodeColourByControl$ = this.completeOnDestroy(new BehaviorSubject<string>('Length'));
  nodeColourBy$: Observable<string>;
  nodeColourPaletteControl$ = this.completeOnDestroy(new BehaviorSubject<ColorPaletteID>('plasma'));
  nodeColourPalette$: Observable<ColorPaletteID>;

  showLegendControl$ = this.completeOnDestroy(new BehaviorSubject<boolean>(true));
  showLegend$: Observable<boolean>;

  sizeAreaTransformControl$ = this.completeOnDestroy(
    new BehaviorSubject<NetworkGraphNodeConfig['sizeAreaTransform']>('linear'),
  );
  sizeAreaTransform$: Observable<NetworkGraphNodeConfig['sizeAreaTransform']>;

  sizeAreaFieldControl$ = this.completeOnDestroy(new BehaviorSubject<string>('Length'));
  sizeAreaField$: Observable<string>;

  nodeConfig$: Observable<{
    config: NetworkGraphNodeConfig;
    selectGroups: SelectGroup<string>[];
    sizeAreaFieldOptions: SelectGroup<string>[];
  }>;

  constructor(
    protected store: Store<AppState>,
    protected graphSidebarOptionsService: GraphSidebarOptionsService,
  ) {
    super(store, graphSidebarOptionsService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.applySavedOptions();
    this.freezeLayout$ = this.freezeLayoutControl$.pipe(distinctUntilChanged());
    this.colourNodes$ = this.colourNodesControl$.pipe(distinctUntilChanged());
    this.nodeColourBy$ = this.nodeColourByControl$.pipe(distinctUntilChanged());
    this.nodeColourPalette$ = this.nodeColourPaletteControl$.pipe(distinctUntilChanged());
    this.showLegend$ = this.showLegendControl$.pipe(distinctUntilChanged());

    this.sizeAreaTransform$ = this.sizeAreaTransformControl$.pipe(distinctUntilChanged());
    this.title$ = this.selectedParams$.pipe(
      map(({ currentSelection }) => `${currentSelection.selectedTable.value.displayName} Summary`),
    );
    const rawData$ = this.store.pipe(
      selectDataWithSelectionForNgsDocument<'clusterSummaryNetwork'>(
        this.documentID,
        'clusterSummaryNetwork',
      ),
      filter(
        ({ data }) =>
          !!data &&
          Object.keys(data?.sequences).length > 0 &&
          alignmentTypes.some(
            (alignment: string) =>
              data?.summaryDataByScoreType[alignment as AlignmentType]?.similarityMatrix?.length >
              0,
          ),
      ),
      distinctUntilChanged(
        (previous, current) =>
          previous.data === current.data &&
          graphSelectionUnchanged(previous?.selection, current.selection),
      ),
      map(({ data }) => data),
      takeUntil(this.ngUnsubscribe),
    );
    rawData$.subscribe((rawData) =>
      this.graphWarningUpdated.next(
        `Showing top ${Object.keys(rawData.idToIndex).length} clusters only`,
      ),
    );
    const sizeFieldName$ = this.selectedParams$.pipe(
      map(({ currentSelection }) =>
        getTotalColumn(
          currentSelection.selectedTable.value.columns.map((x) => x.name),
          currentSelection.selectedTable.value.metadata?.clusters?.usedBeforeCollapsingFrequencies,
        ),
      ),
      distinctUntilChanged(),
    );
    sizeFieldName$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((defaultSizeField) => {
      this.sizeAreaFieldControl$.next(this.savedControls$.value?.sizeAreaField ?? defaultSizeField);
    });
    this.sizeAreaField$ = this.sizeAreaFieldControl$.pipe(distinctUntilChanged());
    this.data$ = combineLatest([rawData$, this.sizeAreaField$]).pipe(
      takeUntil(this.ngUnsubscribe),
      map(([data, sizeAreaField]) => this.processNetwork(data, sizeAreaField)),
    );
    this.data$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(({ nodes }) => {
      const meanSeqs = nodes.reduce((total, node) => total + node.size, 0) / nodes.length;
      const varianceSeqs =
        nodes.reduce((total, node) => total + Math.pow(meanSeqs - node.size, 2), 0) / nodes.length;
      /* we want the default scale to be linear, but we want to make it log if:
        - there are too many fairly large nodes (the mean is too large), or
        - there are some very large nodes (the variance is too large).
        The values are chosen to make certain example documents look good; they might need tweaking.
       */
      if ('sizeAreaTransform' in this.savedControls$.value) {
        this.sizeAreaTransformControl$.next(this.savedControls$.value.sizeAreaTransform);
      } else if (meanSeqs >= 500 && varianceSeqs >= 20000) {
        this.sizeAreaTransformControl$.next('fixed');
      } else if (meanSeqs >= 500) {
        this.sizeAreaTransformControl$.next('log2');
      } else if (varianceSeqs >= 20000) {
        this.sizeAreaTransformControl$.next('balanced');
      } else {
        this.sizeAreaTransformControl$.next('linear');
      }
    });
    this.nodeConfig$ = combineLatest([
      this.nodeColourBy$,
      this.nodeColourPalette$,
      this.data$,
      sizeFieldName$,
      this.sizeAreaTransform$,
    ]).pipe(
      withLatestFrom(rawData$),
      debounceTime(100),
      map(([[name, palette, { nodes }, sizeFieldName, sizeAreaTransform], data]) => {
        const metadataExtractor = new TreeMetadataExtractor(data, [], nodes);
        const values: TreeGraphMetadataMap = metadataExtractor.getMetadataValues(name);
        const metadataColouringConfig = metadataExtractor.getColouringMetadataConfig(
          name,
          palette,
          values,
        );
        const minId: number = parseInt(Object.keys(data.sequences)[0]);
        const selectOptions = Object.keys(data.sequences[minId].metadata)
          .map((column: string) => ({
            displayName: column,
            value: column,
          }))
          .sort();
        const sizeAreaFieldOptions = Object.entries(data.sequences[minId].metadata)
          .filter(
            ([key, value]) =>
              !key?.includes('%') &&
              !key?.endsWith('ID') &&
              value !== null &&
              typeof value === 'number',
          )
          .map(([key, _]) => ({ displayName: key, value: key }))
          .sort();
        const selectGroups = [new SelectGroup(selectOptions)];
        return {
          config: {
            metadataColouringConfig,
            sizeFieldName,
            sizeAreaTransform,
          },
          selectGroups,
          sizeAreaFieldOptions: [new SelectGroup(sizeAreaFieldOptions)],
        };
      }),
    );
    combineLatest([
      this.nodeConfig$.pipe(takeUntil(this.ngUnsubscribe)),
      this.sizeAreaField$,
      this.sizeAreaTransform$,
      this.colourNodes$,
    ])
      .pipe(debounceTime(10))
      .subscribe(
        ([
          { config, selectGroups, sizeAreaFieldOptions },
          sizeAreaField,
          sizeAreaTransform,
          colourNodes,
        ]) => {
          this.controls$.next([
            {
              name: 'layoutOptions',
              label: '',
              type: GraphControlTypeEnum.COMPONENT,
              defaultOption: this.freezeLayoutControl$.value,
              component: NetworkLayoutOptionsComponent as Component,
              layout: 'block',
              injector: (form: FormControl) =>
                Injector.create({
                  providers: [
                    {
                      provide: LayoutOptions,
                      deps: [],
                      useValue: new LayoutOptions(
                        () => this.chartComponent.resetLayout(),
                        this.freezeLayoutControl$,
                        form,
                      ),
                    },
                  ],
                }),
            },
            {
              name: 'colourNodes',
              label: 'Color Nodes',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: this.colourNodesControl$.value,
            },
            {
              name: 'nodeColourBy',
              label: 'Colour Nodes By',
              type: GraphControlTypeEnum.SELECT,
              defaultOption: this.nodeColourByControl$.value,
              options: selectGroups,
              layout: 'block',
            },
            {
              name: 'nodeColourPalette',
              label: 'Node Color Palette',
              type: GraphControlTypeEnum.PALETTE,
              isCategorical: config.metadataColouringConfig.isCategorical,
              numCategories: config.metadataColouringConfig.numGroups,
              defaultOption: config.metadataColouringConfig.colorPalette,
              disabled: false,
            },
            {
              name: 'showLegend',
              label: 'Show Legend',
              type: GraphControlTypeEnum.CHECKBOX,
              defaultOption: this.showLegendControl$.value,
              disabled: !colourNodes,
            },
            {
              name: 'sizeAreaField',
              label: 'Node Size',
              type: GraphControlTypeEnum.SELECT,
              defaultOption: sizeAreaField,
              options: sizeAreaFieldOptions,
            },
            {
              name: 'sizeAreaTransform',
              label: 'Node Size Scaling',
              type: GraphControlTypeEnum.SELECT,
              defaultOption: sizeAreaTransform,
              options: [
                { displayName: 'Fixed Size', value: 'fixed' },
                { displayName: 'Linear', value: 'linear' },
                { displayName: 'Balanced', value: 'balanced' },
                { displayName: 'Logarithmic', value: 'log2' },
              ] as SelectOption<NetworkGraphNodeConfig['sizeAreaTransform']>[],
            },
          ]);
        },
      );
  }

  onControlsChanged({
    layoutOptions,
    colourNodes,
    nodeColourBy,
    nodeColourPalette,
    sizeAreaTransform,
    showLegend,
    sizeAreaField,
  }: any) {
    this.freezeLayoutControl$.next(layoutOptions);
    this.colourNodesControl$.next(colourNodes);
    this.nodeColourByControl$.next(nodeColourBy);
    this.nodeColourPaletteControl$.next(nodeColourPalette);
    this.sizeAreaTransformControl$.next(sizeAreaTransform);
    this.showLegendControl$.next(colourNodes && showLegend);
    this.sizeAreaFieldControl$.next(sizeAreaField);
  }

  processNetwork(data: GraphDataFor<'clusterSummaryNetwork'>, sizeField: string): NetworkData {
    const nodes: NodeType[] = [];
    const links: LinkType[] = [];
    nodes.push(
      ...Object.keys(data?.idToIndex).map((id) => ({
        id,
        group: 1,
        size: data.sequences[parseInt(id)].metadata[sizeField],
        name: data.sequences[parseInt(id)].metadata['Sequence'],
      })),
    );
    const alignmentType: AlignmentType = Object.keys(
      data.summaryDataByScoreType,
    )[0] as AlignmentType;
    const spanningTree = generateSpanningTree(
      data.summaryDataByScoreType[alignmentType].similarityMatrix,
      data.idToIndex,
    );
    links.push(
      ...spanningTree.map(([source, target]: string[]) => ({
        source,
        target,
        value:
          data.summaryDataByScoreType[alignmentType].similarityMatrix[data.idToIndex[source]][
            data.idToIndex[target]
          ],
      })),
    );
    return { nodes, links };
  }
}
