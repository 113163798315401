import { ChangeDetectionStrategy, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { DocumentTableService, Table } from '@geneious/nucleus-api-client';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay, startWith, takeUntil } from 'rxjs/operators';
import { Chip } from 'src/app/shared/chips';
import { AddClusterParams } from '../../../../nucleus/services/models/addCluster.model';
import {
  CombinationRegionChip,
  RegionSelectorChain,
  RegionsSelectorComponent,
} from '../../../shared/regions-selector/regions-selector.component';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { RunnableJobDialog } from '../../dialogV2/runnable-job-dialog';
import { PipelineFormID } from '../../pipeline/pipeline-constants';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import {
  AntibodyAnnotatorOptionValues,
  getOnlyClusterTablesFor,
  getSelectedChain,
} from '../antibody-annotator/antibody-annotator-option-values.model';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { DismissibleDirective } from '../../user-settings/dismissible/dismissible.directive';
import { NgbAlert, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'bx-add-clusters',
  templateUrl: './add-clusters.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DismissibleDirective,
    NgbAlert,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltip,
    MatIconModule,
    RegionsSelectorComponent,
    AsyncPipe,
  ],
})
export class AddClustersComponent extends JobDialogContent implements OnInit, RunnableJobDialog {
  @HostBinding('class') readonly hostClass = 'd-block p-2';

  title = 'Add Clusters';
  earlyRelease = false;
  form = new BxFormGroup({
    regionsSelector: new BxFormControl<(Chip | CombinationRegionChip)[]>([], (ctrl) =>
      ctrl.value?.length ? null : { noNewClusters: 'Add at least one new cluster region' },
    ),
  });
  knowledgeBaseArticle = '';
  tableData$: Observable<Record<string, Table>>;
  tableDataLoading$: Observable<boolean>;
  documentClusterTables$: Observable<Table[]>;
  errors$: Subject<string> = new Subject();
  readonlyRegions$: Observable<Chip[]>;
  readonly creationPipelineOptions: AntibodyAnnotatorOptionValues;
  regionFilter: RegionSelectorChain | 'singleClone';

  private formDefaults: any;
  readonly dismissAlert$: Subject<boolean> = new Subject();

  constructor(
    private readonly documentTableService: DocumentTableService,
    @Inject(PIPELINE_DIALOG_DATA)
    private dialogData: PipelineDialogData<{
      documentID: string;
      creationPipeline: string;
      creationPipelineOptions: AntibodyAnnotatorOptionValues;
    }>,
  ) {
    super('add-cluster', PipelineFormID.ADD_CLUSTER);

    this.creationPipelineOptions = dialogData.otherVariables.creationPipelineOptions;

    const creationPipeline = dialogData.otherVariables.creationPipeline;
    const isGenericAnnotator =
      creationPipeline?.startsWith('peptide') ||
      creationPipeline?.startsWith('protein') ||
      (creationPipeline?.startsWith('single-cell-antibody-analysis') &&
        this.creationPipelineOptions?.sequences_chain === 'genericSequence');
    const isSingleClone =
      creationPipeline?.startsWith('single-cell-antibody-analysis') ||
      creationPipeline?.startsWith('ngs-antibody-annotator');
    this.regionFilter = isGenericAnnotator
      ? 'genericSequence'
      : isSingleClone
        ? 'singleClone'
        : getSelectedChain(this.creationPipelineOptions.sequences_chain);

    this.tableData$ = this.documentTableService
      .getTables(this.dialogData.otherVariables.documentID)
      .pipe(
        map((res) => res.data),
        catchError((e) => {
          this.errors$.next('Error: could not retrieve the document table data');
          this.form.disable();
          return throwError(() => e);
        }),
        takeUntil(this.ngUnsubscribe),
        shareReplay(1),
      );

    this.documentClusterTables$ = this.tableData$.pipe(
      map((tables) => {
        return Object.values(tables).filter(
          ({ status, tableType }) =>
            status.kind === 'Idle' &&
            (tableType === 'AnnotatorResultClusters' ||
              tableType === 'AnnotatorResultClusterGene' ||
              tableType === 'AnnotatorResultInexactCluster'),
        );
      }),
    );
  }

  ngOnInit(): void {
    this.tableDataLoading$ = this.tableData$.pipe(
      catchError(() => of(false)),
      map(() => false),
      startWith(true),
      shareReplay({ bufferSize: 1, refCount: true }),
    );

    this.readonlyRegions$ = this.documentClusterTables$.pipe(
      map((tables) =>
        tables.map(({ displayName }) => ({
          id: displayName,
          label: displayName,
        })),
      ),
    );
    this.formDefaults = this.form.getRawValue();
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  run() {
    const newClusters = this.form.get('regionsSelector').value;
    const params = {
      options: {
        optionValues: {
          ...this.creationPipelineOptions,
          results_onlyClusterTablesFor: getOnlyClusterTablesFor(newClusters),
          results_applyOnlyClusterTablesFor: true,
        },
      },
      selection: {
        ids: [this.dialogData.otherVariables.documentID],
        folderId: this.dialogData.folderID,
        selectAll: false,
      },
    };
    return new AddClusterParams(params);
  }
}
