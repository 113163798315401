<ng-template #selectorPopover>
  <bx-multi-select
    [size]="10"
    class="h-static-2"
    [ngClass]="canNotProvisionallyAddRegions() ? 'mb-4' : 'mb-1'"
    [resizable]="false"
    [showSelectionSummary]="false"
    [formControl]="multiSelectFormControl"
    [selectOptions]="unselectedOptions()"
    [selectMultipleWithSingleClick]="true"
    [showFormErrors]="true"
    [canMarkPristineInvalid]="true"
  >
  </bx-multi-select>
  <button
    type="submit"
    class="btn btn-primary w-100"
    (click)="onOptionsSelected()"
    [disabled]="canNotProvisionallyAddRegions()"
  >
    {{ addProvisionalRegionsButtonText() }}
  </button>
</ng-template>
<div
  cdkDropList
  (cdkDropListDropped)="onDropped($event)"
  [cdkDropListData]="selectedOptions()"
  cdkDropListOrientation="vertical"
  class="mh-static-1 overflow-x-auto d-flex p-2 flex-column border"
>
  <div ngbTooltip="Click and drag to reorder" placement="left">
    @for (option of selectedOptions(); track trackByValue($index, option)) {
      <div class="d-flex flex-row justify-content-start align-items-center">
        <div class="me-3 text-dark">{{ $index + 1 }}.</div>
        <div
          cdkDrag
          cdkDragHandle
          class="draggable badge badge-lg border bg-secondary-subtle fw-normal my-1 p-2 text-dark d-flex overflow-hidden"
        >
          <fa-icon class="small" [icon]="faGripVertical"></fa-icon>
          <span class="text-center mx-2 overflow-hidden text-truncate" [ngbTooltip]="option">{{
            option
          }}</span>
          <button class="btn btn-sm p-0 my-n2" (click)="onOptionRemoved(option)">
            <fa-icon class="small" [icon]="faTimes"></fa-icon>
          </button>
          <div *cdkDragPlaceholder class="placeholder">
            <div class="badge badge-lg border fw-normal p-2 opacity-50">
              <span class="text-dark">{{ option }}</span>
              <button class="btn btn-sm p-0 ms-1 my-n2">
                <fa-icon class="small" [icon]="faTimes"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    }
  </div>
  @let disabled = selectedCount() >= MAX_SELECTION || unselectedCount() === 0;
  <div class="py-2" [ngbTooltip]="addRegionsButtonText()" placement="left">
    <button
      [ngbPopover]="selectorPopover"
      popoverClass="bx-popover-fs-base"
      class="btn rounded-circle"
      [ngClass]="disabled ? 'bg-secondary-subtle text-dark border-0' : 'btn-primary'"
      [disabled]="disabled"
      autoClose="outside"
      container="body"
      placement="top"
      (click)="openPopover()"
      triggers="manual"
      (hidden)="onPopoverHidden()"
      role="button"
    >
      <fa-icon class="align-middle" [icon]="faPlus"></fa-icon>
    </button>
  </div>
</div>
