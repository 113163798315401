import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgsBaseGraphComponent } from '../../ngs-base-graph/ngs-base-graph.component';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core.store';
import { selectDataForNgsDocument } from '../../ngs-graph-data-store/ngs-graph-data-store.selectors';
import { distinctUntilChanged, filter, map, shareReplay, takeUntil } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable, switchMap } from 'rxjs';
import { GraphCircularTreeComponent } from '../../../../../features/graphs/graph-circular-tree/graph-circular-tree.component';
import { AlignmentType, alignmentTypes, GraphDataFor } from '../../ngs-graphs.model';
import {
  TreeGraphColoringHeatmapConfig,
  TreeGraphColoringMetadataConfig,
  TreeGraphDefaultTooltips,
  TreeGraphHeatmapConfig,
  TreeGraphLegendConfig,
  TreeGraphMetadataMap,
  TreeGraphTipLabelConfig,
} from '../../../../../features/graphs/graph-circular-tree/graph-circular-tree.model';
import {
  GraphControlTypeEnum,
  TreeGraphBranchTransform,
  TreeGraphData,
} from '../../../../../features/graphs/graph-sidebar';
import { ColorPaletteID } from '../../../../color/color-palette.model';
import { SelectGroup, SelectOption } from '../../../../models/ui/select-option.model';
import { TreeMetadataExtractor } from '../../../../../features/graphs/datasources/tree-metadata-extractor';
import {
  CircularTreeHeatmapConfigComponent,
  CircularTreeHeatmapConfigOptions,
} from '../../../../../features/graphs/graph-circular-tree/circular-tree-heatmap-config/circular-tree-heatmap-config.component';
import { GridServerState } from '../../../../grid-state/grid-state.interfaces';
import { GridStateService } from '../../../../grid-state/grid-state.service';
import { FeatureSwitchService } from '../../../../../features/feature-switch/feature-switch.service';
import { sanitizeDTSTableOrColumnName } from '../../../../../../nucleus/services/documentService/document-service.v1';
import { AsyncPipe } from '@angular/common';
import { GraphSidebarOptionsService } from '../../../../user-settings/graph-sidebar-options/graph-sidebar-options.service';

interface ClusterSummaryGraphData {
  data: TreeGraphData;
  tipLabels: Map<string, string>;
  tableData: Record<number, { metadata: Record<string, any> }>;
  coloringMetadataConfig: TreeGraphColoringMetadataConfig;
}
@Component({
  selector: 'bx-ngs-cluster-summary-graph',
  templateUrl: './ngs-cluster-summary-graph.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [GraphCircularTreeComponent, AsyncPipe],
})
export class NgsClusterSummaryGraphComponent
  extends NgsBaseGraphComponent<ClusterSummaryGraphData, GraphCircularTreeComponent>
  implements OnInit, OnDestroy
{
  @ViewChild(GraphCircularTreeComponent) chartComponent: GraphCircularTreeComponent;

  title$: Observable<string>;

  heatmapsEnabled$: Observable<boolean>;
  maxTipLabelCharsControl$ = this.completeOnDestroy(new BehaviorSubject<number>(15));
  coloringMetadataKeyControl$ = this.completeOnDestroy(new BehaviorSubject<string>('Total'));
  colorPaletteControl$ = this.completeOnDestroy(new BehaviorSubject<ColorPaletteID>('plasma'));
  showLegendControl$ = this.completeOnDestroy(new BehaviorSubject<boolean>(false));
  showTipLabelsControl$ = this.completeOnDestroy(new BehaviorSubject<boolean>(true));
  branchTransformControl$ = this.completeOnDestroy(
    new BehaviorSubject<TreeGraphBranchTransform>('noTransform'),
  );
  autoColorBranchesControl$ = this.completeOnDestroy(new BehaviorSubject<boolean>(false));
  tipLabelsControl$ = this.completeOnDestroy(new BehaviorSubject<string>('ID'));
  showHeatmapControl$ = this.completeOnDestroy(new BehaviorSubject<boolean>(false));
  heatmapConfigsControl$ = this.completeOnDestroy(
    new BehaviorSubject<TreeGraphColoringHeatmapConfig[]>([]),
  );
  selectedLegendMetadataFieldControl$ = this.completeOnDestroy(
    new BehaviorSubject<string>('Total'),
  );

  showHeatmap$: Observable<boolean>;
  maxTipLabelChars$: Observable<number>;
  colorPalette$: Observable<ColorPaletteID>;
  coloringMetadataKey$: Observable<string>;
  showLegend$: Observable<boolean>;
  showTipLabels$: Observable<boolean>;
  branchTransform$: Observable<TreeGraphBranchTransform>;
  autoColorBranches$: Observable<boolean>;
  tipLabels$: Observable<string>;
  tipLabelConfig$: Observable<TreeGraphTipLabelConfig>;
  legendConfig$: Observable<TreeGraphLegendConfig>;
  heatmapConfig$: Observable<TreeGraphHeatmapConfig>;
  defaultNodeTooltips$: Observable<TreeGraphDefaultTooltips>;
  heatmapConfigs$: Observable<TreeGraphColoringHeatmapConfig[]>;
  selectedLegendMetadataField$: Observable<string>;
  treeMetadataExtractor: TreeMetadataExtractor;
  @ViewChild('graph') container: ElementRef<HTMLDivElement>;

  constructor(
    protected store: Store<AppState>,
    private gridStateService: GridStateService,
    private featureSwitchService: FeatureSwitchService,
    protected graphSidebarOptionsService: GraphSidebarOptionsService,
  ) {
    super(store, graphSidebarOptionsService);
    this.controls$.next([]);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.heatmapsEnabled$ = this.featureSwitchService.isEnabledOnce('clusterSummaryHeatmaps');
    this.tableExportEnabled$.next(false);
    this.maxTipLabelChars$ = this.maxTipLabelCharsControl$.pipe(distinctUntilChanged());
    this.coloringMetadataKey$ = this.coloringMetadataKeyControl$.pipe(distinctUntilChanged());
    this.showLegend$ = this.showLegendControl$.pipe(distinctUntilChanged());
    this.colorPalette$ = this.colorPaletteControl$.pipe(distinctUntilChanged());
    this.showTipLabels$ = this.showTipLabelsControl$.pipe(distinctUntilChanged());
    this.branchTransform$ = this.branchTransformControl$.pipe(distinctUntilChanged());
    this.autoColorBranches$ = this.autoColorBranchesControl$.pipe(distinctUntilChanged());
    this.tipLabels$ = this.tipLabelsControl$.pipe(distinctUntilChanged());
    this.showHeatmap$ = this.showHeatmapControl$.pipe(distinctUntilChanged());
    this.heatmapConfigs$ = this.heatmapConfigsControl$.pipe(distinctUntilChanged());
    this.selectedLegendMetadataField$ =
      this.selectedLegendMetadataFieldControl$.pipe(distinctUntilChanged());
    this.title$ = this.selectedParams$.pipe(
      map(({ currentSelection }) => `${currentSelection.selectedTable.value.displayName} Summary`),
    );
    const rawData$ = this.store.pipe(
      selectDataForNgsDocument<'clusterSummaryTree'>(this.documentID, 'clusterSummaryTree'),
      filter(
        (data) =>
          !!data &&
          Object.keys(data?.sequences).length > 0 &&
          alignmentTypes.some(
            (alignment: string) =>
              data?.summaryDataByScoreType[alignment as AlignmentType]?.similarityTree,
          ) &&
          !!data.idToIndex,
      ),
      shareReplay(1),
      takeUntil(this.ngUnsubscribe),
    );
    rawData$.subscribe((rawData) => {
      // only show warning when there are fewer clusters in graph than in table
      if (rawData.totalSequences > Object.keys(rawData.idToIndex).length) {
        this.graphWarningUpdated.next(
          `Showing top ${Object.keys(rawData.idToIndex).length} clusters only`,
        );
      } else {
        this.graphWarningUpdated.next(null);
      }
      const alignmentType: AlignmentType = Object.keys(
        rawData.summaryDataByScoreType,
      )[0] as AlignmentType;
      const flattenTree = (tree: TreeGraphData): TreeGraphData[] =>
        [tree, ...tree.children.flatMap((child) => flattenTree(child))].filter(
          ({ id }) => !id.includes('_'),
        );
      this.treeMetadataExtractor = new TreeMetadataExtractor(
        rawData,
        [],
        flattenTree(rawData.summaryDataByScoreType[alignmentType].similarityTree),
      );
      this.applySavedOptions();
    });
    const visibleColumnOptions$ = rawData$.pipe(
      switchMap((data) => {
        const minId: number = parseInt(Object.keys(data.sequences)[0]);

        const unsortedUnfilteredColumns = Object.keys(data.sequences[minId].metadata);
        return this.getVisibleOrderedColumns(unsortedUnfilteredColumns).pipe(
          map((visibleOrderedColumns) =>
            unsortedUnfilteredColumns
              .filter((column) => visibleOrderedColumns.includes(column))
              .sort((col1, col2) => {
                return visibleOrderedColumns.indexOf(col1) - visibleOrderedColumns.indexOf(col2);
              }),
          ),
        );
      }),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    const controlOptionsFromData$ = combineLatest([
      rawData$,
      this.colorPalette$,
      this.tipLabels$,
      this.coloringMetadataKey$,
      visibleColumnOptions$,
    ]).pipe(
      map(([data, palette, tipLabelColumn, coloringMetadataKey, visibleColumnOptions]) =>
        this.getControlOptionsFromData(
          data,
          palette,
          tipLabelColumn,
          coloringMetadataKey,
          visibleColumnOptions,
        ),
      ),
      takeUntil(this.ngUnsubscribe),
      shareReplay(1),
    );
    this.data$ = combineLatest([rawData$, controlOptionsFromData$]).pipe(
      map(([data, controlOptions]) => this.processTree(data, controlOptions)),
    );
    this.heatmapConfig$ = combineLatest([
      this.showHeatmap$,
      this.heatmapConfigs$,
      visibleColumnOptions$,
      controlOptionsFromData$,
    ]).pipe(map(([show, heatmapConfigs]) => ({ show, heatmapRowConfigs: heatmapConfigs ?? [] })));
    const availableLegends$ = combineLatest([
      this.autoColorBranches$,
      controlOptionsFromData$,
      this.heatmapConfig$,
      visibleColumnOptions$,
    ]).pipe(
      map(
        ([
          autoColorBranches,
          { coloringMetadataConfig, coloringDisabled },
          { show, heatmapRowConfigs },
        ]) => {
          const availableLegends = [];
          if (coloringMetadataConfig && autoColorBranches) {
            availableLegends.push(
              new SelectGroup(
                [
                  new SelectOption(
                    coloringMetadataConfig.name,
                    coloringMetadataConfig.metadataField,
                  ),
                ],
                heatmapRowConfigs.length > 0 ? 'Branch Metadata' : undefined,
              ),
            );
          }
          const uniqueHeatmapConfigs = [
            ...new Map(
              [...heatmapRowConfigs.values()].map((config) => [config.metadataField, config]),
            ).values(),
          ];
          const heatmapLegendOptions = uniqueHeatmapConfigs
            .filter((config) => {
              if (!coloringDisabled && autoColorBranches) {
                return config.metadataField !== coloringMetadataConfig.metadataField;
              }
              return true;
            })
            .map((config) => new SelectOption(config.name, config.metadataField));
          if (show && heatmapLegendOptions.length > 0) {
            availableLegends.push(
              new SelectGroup(
                heatmapLegendOptions,
                !coloringDisabled && autoColorBranches ? 'Heatmap Metadata' : undefined,
              ),
            );
          }
          return availableLegends;
        },
      ),
      takeUntil(this.ngUnsubscribe),
    );

    this.tipLabelConfig$ = combineLatest([
      this.showTipLabels$,
      this.maxTipLabelChars$,
      this.tipLabels$,
      controlOptionsFromData$,
    ]).pipe(
      map(([show, maxChars, name, { tipLabels }]) => ({ show, name, maxChars, labels: tipLabels })),
    );

    this.defaultNodeTooltips$ = controlOptionsFromData$.pipe(
      map(({ defaultNodeTooltips }) => defaultNodeTooltips),
    );

    this.legendConfig$ = combineLatest([
      this.data$.pipe(map(({ coloringMetadataConfig }) => coloringMetadataConfig)),
      this.heatmapConfig$,
      this.showLegend$,
      this.selectedLegendMetadataField$,
      this.autoColorBranches$,
    ]).pipe(
      map(
        ([
          metadataColoringConfig,
          { show, heatmapRowConfigs },
          showLegend,
          selectedLegendMetadataField,
          autoColorBranches,
        ]) => {
          const showHeatmap = show;
          let selectedShowLegend = showLegend;
          if (!autoColorBranches && (heatmapRowConfigs?.length === 0 || !showHeatmap)) {
            selectedShowLegend = false;
          }
          let selectedLegendMetadataColoringConfig: TreeGraphColoringHeatmapConfig = Array.from(
            (heatmapRowConfigs as TreeGraphColoringHeatmapConfig[]).values(),
          ).find((option) => option.metadataField === selectedLegendMetadataField && showHeatmap);
          if (!selectedLegendMetadataColoringConfig) {
            if (autoColorBranches) {
              selectedLegendMetadataColoringConfig = {
                ...metadataColoringConfig,
                collapsed: false,
              };
            } else {
              selectedLegendMetadataColoringConfig = Array.from(
                (heatmapRowConfigs as TreeGraphColoringHeatmapConfig[]).values(),
              )[0];
            }
          }
          return {
            metadataColoringConfig: selectedLegendMetadataColoringConfig,
            show: selectedShowLegend,
          };
        },
      ),
    );

    const legendDisabled$ = combineLatest([
      controlOptionsFromData$,
      this.autoColorBranches$,
      this.heatmapConfigs$,
      this.showHeatmap$,
    ]).pipe(
      map(
        ([{ coloringDisabled }, autoColorBranches, heatmapConfigs, showHeatmap]) =>
          (coloringDisabled || !autoColorBranches) &&
          ((heatmapConfigs ?? []).length === 0 || !showHeatmap),
      ),
      takeUntil(this.ngUnsubscribe),
    );
    combineLatest([
      controlOptionsFromData$,
      this.autoColorBranches$,
      this.showTipLabels$,
      this.tipLabels$,
      this.maxTipLabelChars$,
      this.branchTransform$,
      this.showHeatmap$,
      this.heatmapConfigs$,
      availableLegends$,
      this.legendConfig$,
      legendDisabled$,
      visibleColumnOptions$,
      this.heatmapsEnabled$,
    ]).subscribe(
      ([
        { coloringMetadataConfig, coloringDisabled, coloringTooltip, tipLabelOptions },
        autoColorBranches,
        showTipsLabels,
        tipLabels,
        maxTipLabelLength,
        branchTransform,
        showHeatmap,
        heatmapConfigs,
        availableLegends,
        legendConfig,
        legendDisabled,
        visibleColumnOptions,
        heatmapsEnabled,
      ]) => {
        const selectGroups = [new SelectGroup(this.fieldsToOptions(visibleColumnOptions))];
        this.controls$.next([
          {
            name: 'branchTransform',
            label: 'Branch Transform',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: branchTransform,
            layout: 'block',
            options: [
              {
                displayName: 'No Transform',
                value: 'noTransform',
              },
              {
                displayName: 'Cladogram',
                value: 'cladogram',
              },
              {
                displayName: 'Equal',
                value: 'equal',
              },
            ],
          },
          {
            name: 'autoColorBranches',
            label: 'Color Branches',
            type: GraphControlTypeEnum.CHECKBOX,
            defaultOption: autoColorBranches,
            disabled: coloringDisabled,
            tooltip: coloringTooltip,
          },
          {
            name: 'coloringMetadataKey',
            label: 'Color Based On',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: coloringMetadataConfig?.name ?? visibleColumnOptions[0],
            options: selectGroups,
            disabled: coloringDisabled,
            tooltip: coloringTooltip,
            layout: 'block',
          },
          {
            name: 'colorPalette',
            label: 'Color Palette',
            type: GraphControlTypeEnum.PALETTE,
            isCategorical: coloringMetadataConfig.isCategorical,
            numCategories: coloringMetadataConfig.numGroups,
            defaultOption: coloringMetadataConfig.colorPalette,
            disabled: coloringDisabled,
            tooltip: coloringTooltip,
          },
          {
            name: 'showLegend',
            label: 'Show Legend',
            type: GraphControlTypeEnum.CHECKBOX,
            defaultOption: legendConfig.show,
            disabled: legendDisabled,
          },
          {
            name: 'selectedLegendMetadataField',
            label: 'Legend to Show',
            type: GraphControlTypeEnum.SELECT,
            defaultOption:
              legendConfig?.metadataColoringConfig?.metadataField &&
              visibleColumnOptions.includes(legendConfig.metadataColoringConfig.metadataField)
                ? legendConfig.metadataColoringConfig.metadataField
                : visibleColumnOptions[0],
            options: availableLegends,
            disabled: legendDisabled,
            layout: 'block',
            hidden: !heatmapsEnabled,
          },
          {
            name: 'showTipLabels',
            label: 'Show Tips Labels',
            type: GraphControlTypeEnum.CHECKBOX,
            defaultOption: showTipsLabels,
          },
          {
            name: 'tipLabels',
            label: 'Tip Labels',
            type: GraphControlTypeEnum.SELECT,
            defaultOption: visibleColumnOptions.includes(tipLabels)
              ? tipLabels
              : visibleColumnOptions[0],
            options: tipLabelOptions,
            layout: 'block',
          },
          {
            name: 'maxTipLabelChars',
            label: 'Max Label Length',
            type: GraphControlTypeEnum.INPUT,
            valueType: 'number',
            defaultOption: maxTipLabelLength,
            layout: 'block',
          },
          {
            name: 'showHeatmap',
            label: 'Show Heatmap',
            type: GraphControlTypeEnum.CHECKBOX,
            defaultOption: showHeatmap,
            disabled: branchTransform !== 'cladogram' || coloringDisabled,
            hidden: !heatmapsEnabled,
            tooltip:
              branchTransform !== 'cladogram'
                ? 'Heatmap is only available with cladogram branch transform'
                : '',
          },
          {
            name: 'heatmapConfigs',
            label: '',
            defaultOption: heatmapConfigs ?? [],
            type: GraphControlTypeEnum.COMPONENT,
            disabled: branchTransform !== 'cladogram' || coloringDisabled,
            layout: 'block',
            hidden: !heatmapsEnabled,
            component: CircularTreeHeatmapConfigComponent as Component,
            injector: (form) =>
              Injector.create({
                providers: [
                  {
                    provide: CircularTreeHeatmapConfigOptions,
                    deps: [],
                    useValue: new CircularTreeHeatmapConfigOptions(
                      selectGroups,
                      form,
                      GraphCircularTreeComponent.MAX_HEATMAP_ROWS,
                      visibleColumnOptions.includes('Total')
                        ? ['Total']
                        : [visibleColumnOptions[0]],
                      heatmapConfigs ?? [],
                    ),
                  },
                  {
                    provide: TreeMetadataExtractor,
                    deps: [],
                    useValue: this.treeMetadataExtractor,
                  },
                ],
              }),
          },
        ]);
      },
    );
  }

  getControlOptionsFromData(
    data: GraphDataFor<'clusterSummaryTree'>,
    palette: ColorPaletteID,
    tipLabelColumn: string,
    possibleColoringMetadataKey: string,
    visibleColumns: string[],
  ) {
    const coloringMetadataKey = visibleColumns.includes(possibleColoringMetadataKey)
      ? possibleColoringMetadataKey
      : visibleColumns[0];
    const tipLabelOptions = this.fieldsToOptions(visibleColumns);
    const selectGroups = [new SelectGroup(tipLabelOptions)];

    const tipLabels: Map<string, string> = new Map();
    const values: TreeGraphMetadataMap =
      this.treeMetadataExtractor.getMetadataValues(coloringMetadataKey);
    const labelColumn = tipLabelColumn;
    Object.values(data.sequences).forEach(({ metadata }: { metadata: Record<string, any> }) => {
      tipLabels.set(`${metadata['ID']}`, `${metadata[labelColumn]}`);
    });
    const coloringMetadataConfig = this.treeMetadataExtractor.getColouringMetadataConfig(
      coloringMetadataKey,
      palette,
      values,
    );
    const coloringDisabled = selectGroups.every((group) => group.isEmpty());
    const coloringTooltip: string = coloringDisabled
      ? 'Please annotate your sequences or add metadata'
      : undefined;
    const defaultNodeTooltips = new Map<string, Record<string, string>>();
    for (let sequence in data.sequences) {
      const { ID, Sequence } = data.sequences[sequence].metadata;
      defaultNodeTooltips.set(ID.toString(), { Sequence, ID });
    }
    return {
      coloringMetadataConfig,
      coloringDisabled,
      coloringTooltip,
      tipLabelOptions,
      tipLabels,
      defaultNodeTooltips,
    };
  }

  processTree(
    rawData: GraphDataFor<'clusterSummaryTree'>,
    {
      tipLabels,
      coloringMetadataConfig,
    }: { tipLabels: Map<string, string>; coloringMetadataConfig: TreeGraphColoringMetadataConfig },
  ): ClusterSummaryGraphData {
    const alignmentType: AlignmentType = Object.keys(
      rawData.summaryDataByScoreType,
    )[0] as AlignmentType;
    return {
      data: rawData.summaryDataByScoreType[alignmentType].similarityTree,
      tableData: rawData.sequences,
      tipLabels,
      coloringMetadataConfig,
    };
  }

  getVisibleOrderedColumns(selectableColumns: string[]) {
    return this.selectedParams$.pipe(
      map(({ currentSelection }) => {
        const { selectedTable } = currentSelection;
        return selectedTable;
      }),
      distinctUntilChanged((table1, table2) => table1?.value?.name === table2?.value?.name),
      switchMap((table) => {
        const tableName = table.value.name;
        const displayName = sanitizeDTSTableOrColumnName(table.value.displayName);
        return this.gridStateService.getGridState(tableName).pipe(
          map((gridState: GridServerState) => {
            const cols = gridState?.columnsState ?? [];
            return cols
              .filter((col) => !col.hide)
              .sort((col1, col2) => col1.position - col2.position)
              .map((col) => {
                const { colId } = col;
                const idColumn = `${displayName} ID`;
                const sequenceColumn = `${displayName}`;
                if (colId === idColumn) {
                  return 'ID';
                } else if (colId === sequenceColumn) {
                  return 'Sequence';
                } else {
                  return colId;
                }
              })
              .filter((col) => selectableColumns.includes(col));
          }),
          map((columns) => (columns.length === 0 ? ['ID', 'Sequence'] : columns)),
        );
      }),
    );
  }

  onControlsChanged({
    autoColorBranches,
    coloringMetadataKey,
    colorPalette,
    showLegend,
    showTipLabels,
    tipLabels,
    maxTipLabelChars,
    branchTransform,
    showHeatmap,
    heatmapConfigs,
    selectedLegendMetadataField,
  }: any) {
    this.autoColorBranchesControl$.next(autoColorBranches);
    this.coloringMetadataKeyControl$.next(coloringMetadataKey);
    this.colorPaletteControl$.next(colorPalette);
    this.showLegendControl$.next((autoColorBranches || showHeatmap) && showLegend);
    this.showTipLabelsControl$.next(showTipLabels);
    this.tipLabelsControl$.next(tipLabels);
    this.maxTipLabelCharsControl$.next(maxTipLabelChars);
    this.branchTransformControl$.next(branchTransform);
    this.showHeatmapControl$.next(branchTransform === 'cladogram' && showHeatmap);
    for (const config of heatmapConfigs ?? []) {
      if (Object.entries(config.values).length === 0) {
        // maps cannot be serialised as-is (they are serialised to empty objects), so if the heatmap config
        // comes from saved sidebar options, we need to reconstruct the values map.
        // we could convert the map to an array before serialising,
        // but this would result in the saved options being much larger than necessary
        config.values = this.treeMetadataExtractor.getMetadataValues(config.metadataField);
      }
    }
    this.heatmapConfigsControl$.next(heatmapConfigs);
    this.selectedLegendMetadataFieldControl$.next(selectedLegendMetadataField);
  }

  fieldsToOptions(fields: string[]) {
    return fields.map((field) => ({
      displayName: field,
      value: field,
    }));
  }
}
