<form [formGroup]="form">
  @let isAntibodyAnnotatorMethod = this.annotatorMethod() === 'AntibodyAnnotator';

  <p class="text-muted px-2">
    The Protein and Peptide annotators can be used to annotate large datasets, cluster duplicates
    and highlight liabilities. They support both shorter nucleotides, or peptides, as well as
    annotating larger protein sequences.
  </p>

  <bx-card headerTitle="Main Options">
    <div class="mb-3 row align-items-center">
      <label
        class="col-4 col-form-label"
        for="antibodyDatabase"
        ngbTooltip="Select 1 or more general template databases"
      >
        Reference database(s):
        <a target="_blank" href="https://help.geneiousbiologics.com/hc/en-us/articles/360045069531">
          <mat-icon class="icon-small">help</mat-icon>
        </a>
      </label>
      <div class="col">
        <bx-multi-select
          id="antibodyDatabase"
          formControlName="antibodyDatabase"
          [selectOptions]="referenceDatabases$ | async"
          entityName="reference database"
          [showMultiple]="multipleRefDbsEnabled$ | async"
          ngFormControlValidator
        >
          <bx-no-general-template-database-message
            bxCustomEmptyMessage
          ></bx-no-general-template-database-message>
        </bx-multi-select>
        <bx-form-errors [control]="form.get('antibodyDatabase')"></bx-form-errors>
      </div>
    </div>

    <bx-name-scheme-select
      *bxShowIf="'nameSchemes'"
      [parent]="form"
      controlName="fileNameSchemeID"
      [associatedNameSchemes]="associatedNameSchemes"
      [colClasses]="{ label: 'col-4', control: 'col' }"
      [ngClass]="{ 'mb-3': ngsSingleCellIsEnabled }"
    ></bx-name-scheme-select>
    @if (ngsSingleCellIsEnabled) {
      <div class="row align-items-center">
        <label
          class="col-4 col-form-label"
          for="annotatorMethod"
          ngbTooltip="Fundamental option to control the analysis granularity - either displaying every individual read, or each unique sequence over the specified coding region"
        >
          Handle Input Sequences:</label
        >
        <div class="col">
          <select
            id="annotatorMethod"
            formControlName="annotatorMethod"
            class="form-select"
            data-spec="annotator-method"
          >
            @if (!shouldOnlySelectNGS) {
              <option value="AntibodyAnnotator">Keep individual sequences (e.g. Sanger)</option>
            }
            <option value="NgsAnnotator">Collapse duplicates (e.g. NGS)</option>
            <option value="SingleClone">
              Collapse duplicates with Barcodes (e.g. Single Cell)
            </option>
          </select>
        </div>
      </div>
    }
  </bx-card>
  @if (ngsSingleCellIsEnabled) {
    @let isSingleCloneAnnotatorMethod = this.annotatorMethod() === 'SingleClone';
    <bx-collapsible-card
      headerTitle="Collapsing and Filtering"
      [collapseByDefault]="isAntibodyAnnotatorMethod"
    >
      @if (isAntibodyAnnotatorMethod) {
        <span class="alert alert-info mb-3 row align-items-center">
          Set 'Handle Input Sequences' to one of the 'Collapse duplicates...' values to enable these
          options.
        </span>
      }
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <label
            for="collapseSequencesPercent"
            ngbTooltip="After assembly or grouping, regions are further clustered to merge sequences which differ due to sequencing errors."
            class="form-check-label"
            [class.opacity-50]="isAntibodyAnnotatorMethod"
          >
            Collapse sequences at least:
          </label>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="collapseSequencesPercent"
              formControlName="collapseSequencesPercent"
              ngFormControlValidator
              [enableIsValidClass]="false"
              class="form-control"
            />
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="col col-form-label" [class.opacity-50]="isAntibodyAnnotatorMethod">
          identical
        </div>
      </div>
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="retainUpstream"
              formControlName="retainUpstream"
              class="form-check-input"
            />
            <label
              for="retainUpstream"
              class="form-check-label"
              ngbTooltip="Retains the specified number of nucleotides upstream of the fully annotated region when trimming the ends of contigs in order to identify duplicates when ignoring incorrect contig ends. If a contig is not long enough to cover the specified range, it will be excluded from the next step in the pipeline."
            >
              Retain upstream of sequenced region:
            </label>
          </div>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="retainUpstreamLength"
              formControlName="retainUpstreamLength"
              ngFormControlValidator
              [enableIsValidClass]="false"
              [min]="0"
              class="form-control"
            />
            <span class="input-group-text">bp</span>
          </div>
        </div>
      </div>

      <div class="row align-items-center mb-3">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="retainDownstream"
              formControlName="retainDownstream"
              class="form-check-input"
            />
            <label
              for="retainDownstream"
              class="form-check-label"
              ngbTooltip="Retains the specified number of nucleotides downstream of the fully annotated region when trimming the ends of contigs in order to identify duplicates when ignoring incorrect contig ends. If a contig is not long enough to cover the specified range, it will be excluded from the next step in the pipeline."
            >
              Retain downstream of sequenced region:
            </label>
          </div>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="retainDownstreamLength"
              formControlName="retainDownstreamLength"
              ngFormControlValidator
              [enableIsValidClass]="false"
              [min]="0"
              class="form-control"
            />
            <span class="input-group-text">bp</span>
          </div>
        </div>
      </div>
      <hr />
      @if (annotatorMethod() !== 'SingleClone') {
        <span class="alert alert-info mb-3 row align-items-center">
          Set 'Handle Input Sequences' to 'Collapse duplicates with Barcodes (e.g. Single Cell)' to
          enable these options.
        </span>
      }
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="keepUnmerged"
              formControlName="keepUnmerged"
              class="form-check-input"
              data-testid="keep-unmerged"
            />
            <label
              class="form-check-label"
              for="keepUnmerged"
              ngbTooltip="Specifies that paired reads which failed to merge should be used in the next step of the pipeline. In use cases where pairs are expected to overlap, discarding unmerged pairs is recommended in order to improve assembly accuracy."
            >
              Keep unmerged reads
            </label>
          </div>
        </div>
      </div>
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="deNovoAssemblyRequired"
              formControlName="deNovoAssemblyRequired"
              class="form-check-input"
              data-testid="deNovoAssemblyRequired"
            />
            <label
              class="form-check-label"
              for="deNovoAssemblyRequired"
              ngbTooltip="Perform de novo assembly if reads are fully annotated region fragments. Otherwise reads are grouped via the fully annotated region annotation."
            >
              De novo assembly required
            </label>
          </div>
        </div>
      </div>
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <label
            for="minLength"
            class="col-form-label"
            [class.opacity-50]="!isSingleCloneAnnotatorMethod"
          >
            Only keep reads longer than:
          </label>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="minLength"
              formControlName="minLength"
              ngFormControlValidator
              [enableIsValidClass]="false"
              class="form-control"
              data-testid="minLength"
            />
            <span class="input-group-text">bp</span>
          </div>
        </div>
      </div>
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="onlyUseLongest"
              formControlName="onlyUseLongest"
              class="form-check-input"
              data-testid="onlyUseLongest"
            />
            <label
              for="onlyUseLongest"
              class="form-check-label"
              ngbTooltip="This is to improve performance on large data sets where excessive data significantly slows down analysis. 500 reads is generally sufficient"
            >
              Only use longest:
            </label>
          </div>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="onlyUseLongestLength"
              formControlName="onlyUseLongestLength"
              ngFormControlValidator
              class="form-control"
            />
          </div>
        </div>
        <div class="col col-form-label" [class.opacity-50]="!isSingleCloneAnnotatorMethod">
          reads from each list/barcode
        </div>
      </div>
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <label
            for="minimumSignificantOfCellPercent"
            ngbTooltip="Regions deemed insignificant are retained but annotated as not significant."
            class="form-check-label"
            [class.opacity-50]="!isSingleCloneAnnotatorMethod"
          >
            Significant sequences have at least:
          </label>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="minimumSignificantOfCellPercent"
              formControlName="minimumSignificantOfCellPercent"
              ngFormControlValidator
              class="form-control"
              data-testid="minimumSignificantOfCellPercent"
            />
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="col col-form-label" [class.opacity-50]="!isSingleCloneAnnotatorMethod">
          of the read count of the cell/barcode
        </div>
      </div>
      <div class="mb-3 row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <label
            for="minimumSignificantReadCount"
            ngbTooltip="Regions deemed insignificant are retained but annotated as not significant."
            class="form-check-label"
            [class.opacity-50]="!isSingleCloneAnnotatorMethod"
          >
            Significant sequences have at least:
          </label>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="minimumSignificantReadCount"
              formControlName="minimumSignificantReadCount"
              ngFormControlValidator
              class="form-control"
              data-testid="minimumSignificantReadCount"
            />
          </div>
        </div>
        <div class="col col-form-label" [class.opacity-50]="!isSingleCloneAnnotatorMethod">
          reads
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-9 col-lg-5 d-flex align-items-center">
          <label
            for="strictMinimumVariantSupport"
            class="form-check-label"
            [class.opacity-50]="!isSingleCloneAnnotatorMethod"
          >
            Only keep sequences with at least:
          </label>
        </div>
        <div class="col-sm-3 col-lg-2">
          <div class="input-group">
            <input
              type="number"
              id="strictMinimumVariantSupport"
              formControlName="strictMinimumVariantSupport"
              ngFormControlValidator
              class="form-control"
              data-testid="strictMinimumVariantSupport"
            />
            <span class="input-group-text">%</span>
          </div>
        </div>
        <div class="col col-form-label" [class.opacity-50]="!isSingleCloneAnnotatorMethod">
          of the read count of the dominant sequence
        </div>
      </div>
    </bx-collapsible-card>
  }

  <bx-collapsible-card headerTitle="Analysis Options" [collapseByDefault]="true">
    <div class="mb-3 row">
      <label for="antibodyAnnotator_sequences_addNumberingAnnotations" class="col-4 col-form-label">
        Numbering Annotations:
      </label>
      <div class="col">
        <select
          formControlName="antibodyAnnotator_sequences_addNumberingAnnotations"
          id="antibodyAnnotator_sequences_addNumberingAnnotations"
          class="form-select"
        >
          <option [value]="true">Simple Numbering</option>
          <option [value]="false">No Numbering</option>
        </select>
      </div>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="antibodyAnnotator_genes_annotateGermlineGeneDifferences"
        formControlName="antibodyAnnotator_genes_annotateGermlineGeneDifferences"
        class="form-check-input"
      />
      <label
        for="antibodyAnnotator_genes_annotateGermlineGeneDifferences"
        ngbTooltip="Selecting this option will make Antibody Annotator annotate how each query sequence differs from its best match in the reference database. Differences will be annotated for differing nucleotides and amino acids. Slows analysis with large datasets."
        class="form-check-label"
      >
        Annotate variants from reference database
      </label>
    </div>

    <div class="mb-3 form-check">
      <input
        type="checkbox"
        id="calculateProteinStatistics"
        formControlName="calculateProteinStatistics"
        class="form-check-input"
      />
      <label
        for="calculateProteinStatistics"
        ngbTooltip="Calculate protein statistics such as Molecular Weight and Isoelectric Point for the VJ, VDJ and scFv regions."
        class="form-check-label"
      >
        Calculate protein statistics
      </label>
    </div>

    <div class="row align-items-center mb-0">
      <div class="col-4 d-flex align-items-center">
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="includeLiabilities"
            formControlName="includeLiabilities"
            class="form-check-input"
          />
          <label
            for="includeLiabilities"
            ngbTooltip="Search and score motifs liable to post-translational modifications or any other types of modifications or beneficial motifs. Slows analysis with large datasets."
            class="form-check-label"
          >
            Find liabilities and assets:
            <a
              target="_blank"
              href="https://help.geneiousbiologics.com/hc/en-us/articles/360045070271"
            >
              <mat-icon class="icon-small">help</mat-icon>
            </a>
          </label>
        </div>
      </div>
      <div class="col">
        <textarea
          formControlName="antibodyAnnotator_liabilities_liabilitiesText"
          rows="3"
          class="form-control"
        >
        </textarea>
      </div>
    </div>
  </bx-collapsible-card>

  <bx-clustering-options-card
    filterRegionsFor="genericSequence"
    [collapseByDefault]="true"
    [clusterSummaryFilterEnabled]="isAntibodyAnnotatorMethod"
  >
  </bx-clustering-options-card>

  <bx-collapsible-card headerTitle="Advanced Options" [collapseByDefault]="true">
    <div class="mb-3 row">
      <label
        for="antibodyAnnotator_sequences_queryGeneticCode"
        class="col-4 col-form-label"
        ngbTooltip="The genetic code to use for translating nucleotide sequences. Codes obtained from NCBI."
      >
        Genetic code:
      </label>
      <div class="col-8">
        <select
          id="antibodyAnnotator_sequences_queryGeneticCode"
          formControlName="antibodyAnnotator_sequences_queryGeneticCode"
          class="form-select"
        >
          @for (geneticCode of geneticCodes$ | async; track geneticCode) {
            <option [value]="geneticCode.value">
              {{ geneticCode.displayName }}
            </option>
          }
        </select>
      </div>
    </div>
    <div class="mb-3 row align-items-center">
      <label
        for="antibodyAnnotator_genes_ambiguousGenesStrategy"
        class="col-4 col-form-label"
        ngbTooltip="How to handle cases where query sequences are equally close to multiple reference genes."
      >
        Record equal reference sequence match as:
      </label>
      <div class="col-8">
        <select
          formControlName="antibodyAnnotator_genes_ambiguousGenesStrategy"
          id="antibodyAnnotator_genes_ambiguousGenesStrategy"
          class="form-select"
        >
          <option value="partialFrequency">
            Each sequence with partial frequency (e.g. frequency of 0.5 for each sequence)
          </option>
          <option value="groupOfGenes">
            Group of sequences (e.g. assigned to a cluster called IGHD1-26/IGHD2-15)
          </option>
          <option value="unknown">
            Unknown (treat it the same as if it didn't match any sequence)
          </option>
        </select>
      </div>
    </div>

    @if (isAntibodyAnnotatorMethod) {
      <div class="row gx-0 mb-0">
        <div class="col-auto d-flex align-items-center">
          <div class="form-check form-check-inline">
            <input
              type="checkbox"
              id="trimSequences"
              formControlName="antibodyAnnotator_results_trimSequences"
              class="form-check-input"
            />
            <label
              for="trimSequences"
              ngbTooltip="Select this option to trim a specified number of bases upstream and downstream in fully annotated query sequences."
              class="form-check-label"
            >
              Trim each side of fully annotated region if over:
            </label>
          </div>
        </div>
        <div class="col-auto d-flex align-items-center ms-3">
          <input
            type="number"
            id="trimSequencesLength"
            formControlName="antibodyAnnotator_results_trimSequencesLength"
            ngFormControlValidator
            min="0"
            max="9999"
            class="form-control me-2"
          />
          <label for="trimSequencesLength" class="col-form-label text-nowrap">base pairs</label>
          <div class="invalid-feedback">Base pairs must be between 0 and 9999</div>
        </div>
      </div>
    }
  </bx-collapsible-card>

  <bx-card headerTitle="Output" customClass="mb-0">
    <bx-pipeline-output
      [resultNameFormControl]="form.get('resultName')"
      [outputNameFormControl]="form.get('outputFolderName')"
    ></bx-pipeline-output>
  </bx-card>
</form>
