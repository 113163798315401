import { combineReducers, createReducer, on } from '@ngrx/store';
import { loadInitialUserSettings, loadInitialUserSettingsSuccess } from './user-settings.actions';
import { formStateReducer } from './form-state/form-state.reducer';
import { UserSettingsState } from './user-settings.model';
import { profilesReducer } from './profiles/profiles.reducer';
import { gridStateReducer } from '../grid-state/grid-state.reducers';
import { uiSettingsReducer } from './ui-settings/ui-settings.reducer';
import { sequenceViewerPreferencesReducer } from './sequence-viewer-preferences/sequence-viewer-preferences.reducer';
import { dismissibleReducer } from './dismissible/dismissible.reducer';
import { createEntityAdapter } from '@ngrx/entity';
import { UserSetting } from '@geneious/nucleus-api-client';
import { graphSidebarOptionsReducer } from './graph-sidebar-options/graph-sidebar-options.reducer';

export const adapter = createEntityAdapter<UserSetting>();

export const userSettingsReducer = combineReducers<UserSettingsState>({
  formState: formStateReducer,
  profiles: profilesReducer,
  gridStates: gridStateReducer,
  uiSettings: uiSettingsReducer,
  sequenceViewerPreferences: sequenceViewerPreferencesReducer,
  dismissibleStates: dismissibleReducer,
  initialSettingsLoaded: createReducer(
    false,
    on(loadInitialUserSettings, () => false),
    on(loadInitialUserSettingsSuccess, () => true),
  ),
  graphSidebarOptions: graphSidebarOptionsReducer,
});
