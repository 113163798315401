import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  Inject,
  OnInit,
  Signal,
} from '@angular/core';
import { JobDialogContent } from '../../dialogV2/jobDialogContent.model';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NucleusPipelineID, PipelineFormID } from '../../pipeline/pipeline-constants';
import {
  AmbiguousGenesStrategy,
  AnnotatedGeneRange,
  defaultClusterCombos,
  defaultClusterCombosGenes,
  defaultPAndPLiabilities,
  getOnlyClusterTablesFor,
  SequencesAnnotationStyle,
  SingleCellOptionValues,
} from '../antibody-annotator/antibody-annotator-option-values.model';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, share, startWith, take, takeUntil } from 'rxjs/operators';
import { SelectOption } from '../../models/ui/select-option.model';
import { PipelineService } from '../../pipeline/pipeline.service';
import {
  BxFormControl,
  BxFormGroup,
} from '../../user-settings/form-state/bx-form-group/bx-form-group';
import { PipelineFormControlValidatorsService } from '../pipeline-form-control-validators.service';
import { PIPELINE_DIALOG_DATA, PipelineDialogData } from '../pipeline-dialog-v2/pipeline-dialog-v2';
import { defaultGenericRegionChips } from '../../../shared/regions-selector/regions-selector';
import { toggleControlsAvailabilityOnBoolean } from '../../../shared/form-helpers/toggle-controls-availability';
import { clusterComboValidator } from '../../ngs/validators/cluster-combo-validator';
import { SelectionState } from '../../../features/grid/grid.component';
import {
  AnnotatorMethod,
  PeptideAnnotatorJobOptionsV1,
  PeptideAnnotatorJobParametersV1,
} from '../../../../nucleus/services/models/peptideAnnotatorOptions.model';
import { FeatureSwitchService } from '../../../features/feature-switch/feature-switch.service';
import { DatabaseTypeEnum } from '../../blast/database-type';
import { currentValueAndChanges, restrictControlValues } from 'src/app/shared/utils/forms';
import { CardComponent } from '../../../shared/card/card.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { MatIconModule } from '@angular/material/icon';
import { MultiSelectComponent } from '../../../shared/select/multi-select.component';
import { NgFormControlValidatorDirective } from '../../../shared/form-helpers/ng-form-control-validator.directive';
import { NoGeneralTemplateDatabaseMessageComponent } from './no-general-template-database-message/no-general-template-database-message.component';
import { FormErrorsComponent } from '../../../shared/form-errors/form-errors.component';
import { ShowIfDirective } from '../../../shared/access-check/directives/show/show-if.directive';
import { NameSchemeSelectComponent } from '../../name-schemes/name-scheme-select/name-scheme-select.component';
import { CollapsibleCardComponent } from '../../../shared/collapsible-card/collapsible-card.component';
import { ClusteringOptionsCardComponent } from '../shared/clustering-options-card/clustering-options-card.component';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { PipelineOutputComponent } from '../../pipeline/pipeline-output/pipeline-output.component';
import { SelectComponent } from '../../../shared/select/select.component';
import { toggleControlsAvailabilityOnValues } from '../../../shared/form-helpers/toggle-controls-availability';
import { toSignal } from '@angular/core/rxjs-interop';
import { SequenceUtils } from '../../sequence-utils';
import { SequenceAlphabet } from '../../sequence-alphabet.model';
import { select, Store } from '@ngrx/store';
import { selectFormStateOptions } from '../../user-settings/form-state/form-state.selectors';
import { AppState } from '../../core.store';

@Component({
  selector: 'bx-peptide-annotator',
  templateUrl: './peptide-and-protein-annotator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CardComponent,
    NgbTooltip,
    MatIconModule,
    MultiSelectComponent,
    NgFormControlValidatorDirective,
    NoGeneralTemplateDatabaseMessageComponent,
    FormErrorsComponent,
    ShowIfDirective,
    NameSchemeSelectComponent,
    CollapsibleCardComponent,
    ClusteringOptionsCardComponent,
    PipelineOutputComponent,
    AsyncPipe,
    SelectComponent,
    NgClass,
    NgStyle,
  ],
})
export class PeptideAndProteinAnnotatorComponent extends JobDialogContent implements OnInit {
  public static SANGER_LIMIT = 10_000;
  title: string;
  knowledgeBaseArticle: string;
  earlyRelease: boolean;

  referenceDatabases$: Observable<SelectOption[][]>;
  geneticCodes$: Observable<SelectOption[]>;
  associatedNameSchemes: Set<string>;
  multipleRefDbsEnabled$: Observable<boolean>;
  ngsSingleCellIsEnabled: boolean;
  shouldOnlySelectNGS: boolean;

  readonly form = this.initFormControls();
  rawAnnotatorMethod: Signal<AnnotatorMethod>;
  annotatorMethod: Signal<AnnotatorMethod>;
  formState: Signal<typeof this.form.value>;
  private readonly formDefaults = this.form.getRawValue();
  private readonly selected: SelectionState;

  constructor(
    private readonly pipelineService: PipelineService,
    private readonly validatorService: PipelineFormControlValidatorsService,
    private readonly featureSwitchService: FeatureSwitchService,
    @Inject(PIPELINE_DIALOG_DATA)
    private dialogData: PipelineDialogData<PeptideAndProteinDialogData>,
    private store: Store<AppState>,
  ) {
    super(dialogData.otherVariables.pipelineId, dialogData.otherVariables.pipelineFormId);
    this.multipleRefDbsEnabled$ = this.featureSwitchService.isEnabledOnce(
      'multipleReferenceDatabases',
    );
    this.selected = dialogData.selected;

    this.title = this.dialogData.otherVariables.title;
    this.knowledgeBaseArticle = this.dialogData.otherVariables.knowledgeBaseArticle;
    this.earlyRelease = this.dialogData.otherVariables.earlyRelease;
    this.shouldOnlySelectNGS =
      dialogData?.selected?.selectedRows.reduce(
        (acc, row) => acc + parseInt((row.number_of_sequences as string) ?? '1'),
        0,
      ) > PeptideAndProteinAnnotatorComponent.SANGER_LIMIT;
    this.ngsSingleCellIsEnabled = SequenceUtils.isSelectionOfType(
      SequenceAlphabet.NUCLEOTIDE,
      dialogData.selected.selectedRows,
    );
    if (this.shouldOnlySelectNGS && this.ngsSingleCellIsEnabled) {
      this.form.controls.annotatorMethod.setValue('NgsAnnotator');
    }
    this.rawAnnotatorMethod = toSignal(
      currentValueAndChanges(this.form.controls.annotatorMethod, this.ngUnsubscribe),
    );
    this.annotatorMethod = computed(() => {
      const settings = this.rawAnnotatorMethod();
      return this.ngsSingleCellIsEnabled ? settings : 'AntibodyAnnotator';
    });
    this.formState = toSignal(
      this.store.pipe(
        select(selectFormStateOptions(this.pipelineFormID)),
        filter((options) => !!options),
        take(1),
        takeUntil(this.ngUnsubscribe),
        startWith({}),
      ),
    );
    effect(() => {
      const annotatorMethod = this.annotatorMethod();
      const formState = this.formState();
      if (annotatorMethod === 'NgsAnnotator') {
        this.form.controls.minLength.setValue(1);
        this.form.controls.deNovoAssemblyRequired.setValue(false);
        this.form.controls.keepUnmerged.setValue(false);
        this.form.controls.onlyUseLongest.setValue(false);
        this.form.controls.minimumSignificantOfCellPercent.setValue(0);
        this.form.controls.minimumSignificantReadCount.setValue(1);
        this.form.controls.strictMinimumVariantSupport.setValue(0);
      } else if (annotatorMethod === 'AntibodyAnnotator' && this.shouldOnlySelectNGS) {
        this.form.controls.annotatorMethod.setValue('NgsAnnotator');
      } else if (
        annotatorMethod === 'SingleClone' &&
        // set the single-clone settings to default values UNLESS they're the saved form state values
        (formState?.annotatorMethod !== 'SingleClone' ||
          this.form.controls.minLength.getRawValue() !== formState?.minLength ||
          this.form.controls.minimumSignificantOfCellPercent.getRawValue() !==
            formState?.minimumSignificantOfCellPercent ||
          this.form.controls.minimumSignificantReadCount.getRawValue() !==
            formState?.minimumSignificantReadCount ||
          this.form.controls.strictMinimumVariantSupport.getRawValue() !==
            formState?.strictMinimumVariantSupport)
      ) {
        this.form.controls.minLength.setValue(50);
        this.form.controls.minimumSignificantOfCellPercent.setValue(20);
        this.form.controls.minimumSignificantReadCount.setValue(5);
        this.form.controls.strictMinimumVariantSupport.setValue(1);
      }
    });
  }

  ngOnInit() {
    this.toggleControlsAvailability();

    this.setReferenceDatabaseFields();

    this.geneticCodes$ = this.pipelineService.externalServices.geneticCodesMapToGeneiousPrimeName
      .valueSource()
      .pipe(first(), share());

    this.associatedNameSchemes = this.pipelineService.nameSchemesSetOnDocuments(this.selected);

    this.form.setControl(
      'antibodyAnnotator_results_clusterCombos',
      new FormControl(defaultClusterCombosGenes),
    );
  }

  private setReferenceDatabaseFields() {
    const emptyDatabaseOption = new SelectOption('No Reference Database', undefined);
    this.referenceDatabases$ = this.pipelineService
      .getDatabases(DatabaseTypeEnum.GENERAL_TEMPLATE)
      .pipe(
        map((dbs) => {
          if (dbs[0].length === 0 && dbs[1].length === 0) {
            return [];
          }
          dbs[0].push(emptyDatabaseOption);
          return dbs;
        }),
        share(),
      );
    this.referenceDatabases$
      .pipe(
        take(1),
        map((groups) => groups.flatMap((group) => group.map((option) => option.value))),
      )
      .subscribe((allowedValues) =>
        restrictControlValues(this.form.controls.antibodyDatabase, allowedValues, {
          takeUntil: this.ngUnsubscribe,
        }),
      );

    setTimeout(() => {
      this.form.controls.antibodyDatabase.setAsyncValidators(
        this.validatorService.databaseValidator(true),
      );
      this.form.controls.antibodyDatabase.updateValueAndValidity();
    });

    // disabled gene differences option if there is no reference database selected.
    this.form.controls.antibodyDatabase.valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((dbs) => {
        if (!dbs || dbs.length === 0 || dbs.filter((db) => !!db).length === 0) {
          this.form.controls.antibodyAnnotator_genes_annotateGermlineGeneDifferences.disable();
        } else {
          this.form.controls.antibodyAnnotator_genes_annotateGermlineGeneDifferences.enable();
        }
      });
  }

  run() {
    const formValues = this.form.value;
    // We need to do this as an empty database is an undefined value in the array, eg [undefined]
    if (formValues.antibodyDatabase) {
      formValues.antibodyDatabase = formValues.antibodyDatabase.filter((i) => !!i);
    }

    const collapseType = this.annotatorMethod();
    const ngsDefaults =
      collapseType === 'NgsAnnotator'
        ? {
            antibodyAnnotator_results_useBeforeCollapsingFrequencies: true,
            deNovoAssemblyRequired: false,
            trimPrimers: false,
            keepUnmerged: false,
            minimumSignificantOfCellPercent: 0,
            minimumSignificantReadCount: 1,
            minimumVariantSupport: 0,
            strictMinimumVariantSupport: 0,
            minLength: 1,
            onlyUseLongest: false,
          }
        : {};
    const formValue = {
      ...ngsDefaults,
      ...formValues,
      antibodyAnnotator_results_applyOnlyClusterTablesFor: true,
      chain: 'genericSequence',
      antibodyAnnotator_results_onlyClusterTablesFor: getOnlyClusterTablesFor(
        formValues.regionsSelector,
      ),
      antibodyDatabase: formValues.antibodyDatabase ?? [],
      antibodyAnnotator_results_summaryFilter: formValues.results_summaryFilter,
      antibodyAnnotator_results_applySummaryFilter: formValues.results_applySummaryFilter,
      antibodyAnnotator_results_applySummaryScoreFilter: formValues.results_applySummaryScoreFilter,
      antibodyAnnotator_results_summaryScoreThreshold: formValues.results_summaryScoreThreshold,
    };

    const outputFolderName = this.form.get('outputFolderName').value;
    const resultName = this.form.get('resultName').value;

    const collapseOptions: Record<string, unknown> = {
      clusterVDJ: true,
      clusterVDJthresholdPercent: formValues.collapseSequencesPercent,
    };

    // Remove form keys which aren't option values
    delete formValue.resultName;
    delete formValue.outputFolderName;
    delete formValue.collapseSequencesPercent;
    delete formValue.regionsSelector;
    delete formValue.results_summaryFilter;
    delete formValue.results_applySummaryFilter;
    delete formValue.results_applySummaryScoreFilter;
    delete formValue.results_summaryScoreThreshold;

    let optionValues: SingleCellOptionValues = {
      ...formValue,
    };
    if (collapseType !== 'AntibodyAnnotator') {
      optionValues = {
        ...optionValues,
        ...collapseOptions,
      };
    }
    const options: PeptideAnnotatorJobOptionsV1<typeof collapseType> = {
      optionValues,
      resultName: resultName,
    };

    const parameters = {
      options,
      selection: {
        selectAll: this.selected.selectAll,
        folderId: this.dialogData.folderID,
        ids: this.selected.ids,
      },
      output: {
        outputFolderName: outputFolderName,
      },
    };

    return new PeptideAnnotatorJobParametersV1(
      parameters.selection,
      parameters.options,
      parameters.output,
    );
  }

  getFormDefaults() {
    return this.formDefaults;
  }

  private initFormControls() {
    const requiredNumber = (initial: number, min: number, max: number) =>
      new BxFormControl(initial, [Validators.required, Validators.min(min), Validators.max(max)]);

    return new BxFormGroup({
      annotatorMethod: new BxFormControl<AnnotatorMethod>('AntibodyAnnotator'),
      retainUpstream: new BxFormControl<boolean>(false),
      retainUpstreamLength: new BxFormControl<number>(10),
      retainDownstream: new BxFormControl<boolean>(false),
      retainDownstreamLength: new BxFormControl<number>(30),
      fileNameSchemeID: new BxFormControl<string>(undefined),
      resultName: JobDialogContent.getResultNameControl(),
      antibodyDatabase: new BxFormControl<string[]>(undefined),
      antibodyAnnotator_genes_annotateGermlineGeneDifferences: new BxFormControl(false),
      antibodyAnnotator_sequences_queryGeneticCode: new BxFormControl(
        'universal',
        Validators.required,
      ),
      antibodyAnnotator_sequences_annotationStyle: new BxFormControl<SequencesAnnotationStyle>(
        'IMGT',
        Validators.required,
      ),
      antibodyAnnotator_sequences_addNumberingAnnotations: new BxFormControl(true),
      antibodyAnnotator_genes_ambiguousGenesStrategy: new BxFormControl<AmbiguousGenesStrategy>(
        'partialFrequency',
        Validators.required,
      ),
      calculateProteinStatistics: new BxFormControl(false),
      includeLiabilities: new BxFormControl(false),
      antibodyAnnotator_liabilities_liabilitiesText: new BxFormControl(
        defaultPAndPLiabilities,
        Validators.required,
      ),
      regionsSelector: new BxFormControl(defaultGenericRegionChips, Validators.required),
      antibodyAnnotator_results_clusterCombos: new BxFormControl(
        defaultClusterCombos,
        clusterComboValidator,
      ),
      antibodyAnnotator_results_trimSequences: new BxFormControl(false),
      antibodyAnnotator_results_trimSequencesLength: requiredNumber(10, 0, 9999),

      results_applySummaryScoreFilter: new BxFormControl(false),
      results_summaryScoreThreshold: requiredNumber(-1000, -99999, 99999),

      results_applySummaryFilter: new BxFormControl(false),
      results_summaryFilter: new BxFormControl('fullyAnnotated', Validators.required),

      antibodyAnnotator_results_fullyAnnotatedStart: new BxFormControl<AnnotatedGeneRange>(
        'FR1',
        Validators.required,
      ),
      antibodyAnnotator_results_fullyAnnotatedEnd: new BxFormControl<AnnotatedGeneRange>(
        'FR4',
        Validators.required,
      ),
      collapseSequencesPercent: requiredNumber(100, 0, 100),
      outputFolderName: JobDialogContent.getResultNameControl(),
      deNovoAssemblyRequired: new BxFormControl(false),
      minLength: requiredNumber(50, 1, 999999),
      onlyUseLongest: new BxFormControl(true),
      onlyUseLongestLength: requiredNumber(50, 2, 999999),
      minimumSignificantOfCellPercent: requiredNumber(20, 0, 100),
      minimumSignificantReadCount: requiredNumber(5, 1, 1000000),
      strictMinimumVariantSupport: requiredNumber(1, 0, 100),
      keepUnmerged: new BxFormControl(true),
    });
  }

  /**
   * Handle toggling the availability (disable/enable) for each of these form controls.
   */
  private toggleControlsAvailability() {
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.includeLiabilities,
      [this.form.controls.antibodyAnnotator_liabilities_liabilitiesText],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_applySummaryScoreFilter,
      [this.form.controls.results_summaryScoreThreshold],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.results_applySummaryFilter,
      [this.form.controls.results_summaryFilter],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.antibodyAnnotator_results_trimSequences,
      [this.form.controls.antibodyAnnotator_results_trimSequencesLength],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.onlyUseLongest,
      [this.form.controls.onlyUseLongestLength],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnValues(
      this.form.controls.annotatorMethod,
      [
        this.form.controls.results_applySummaryFilter,
        this.form.controls.antibodyAnnotator_results_trimSequences,
      ],
      ['AntibodyAnnotator'],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnValues(
      this.form.controls.annotatorMethod,
      [this.form.controls.collapseSequencesPercent],
      ['NgsAnnotator', 'SingleClone'],
      this.ngUnsubscribe,
    );
    combineLatest([
      currentValueAndChanges(this.form.controls.antibodyDatabase),
      currentValueAndChanges(this.form.controls.annotatorMethod),
    ])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(([database, method]) => {
        if (
          !database ||
          database.length === 0 ||
          (database.length === 1 && !database[0]) ||
          method === 'AntibodyAnnotator'
        ) {
          this.form.controls.retainUpstream.disable();
          this.form.controls.retainDownstream.disable();
          this.form.controls.retainUpstreamLength.disable();
          this.form.controls.retainDownstreamLength.disable();
        } else {
          this.form.controls.retainUpstream.enable();
          this.form.controls.retainDownstream.enable();
        }
      });
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.retainUpstream,
      [this.form.controls.retainUpstreamLength],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnBoolean(
      this.form.controls.retainDownstream,
      [this.form.controls.retainDownstreamLength],
      this.ngUnsubscribe,
    );
    toggleControlsAvailabilityOnValues(
      this.form.controls.annotatorMethod,
      [
        this.form.controls.deNovoAssemblyRequired,
        this.form.controls.minLength,
        this.form.controls.onlyUseLongest,
        this.form.controls.onlyUseLongestLength,
        this.form.controls.minimumSignificantOfCellPercent,
        this.form.controls.minimumSignificantReadCount,
        this.form.controls.keepUnmerged,
        this.form.controls.strictMinimumVariantSupport,
      ],
      ['SingleClone'],
      this.ngUnsubscribe,
    );
  }
}

export interface PeptideAndProteinDialogData {
  // Note. If these two dialogs start to diverge a lot, we should probably copy + paste them into two separate
  // components.
  title: string;
  knowledgeBaseArticle?: string;
  earlyRelease?: boolean;
  pipelineId: NucleusPipelineID;
  pipelineFormId: PipelineFormID;
}
