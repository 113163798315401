import {
  Component,
  computed,
  Injectable,
  OnDestroy,
  OnInit,
  signal,
  Signal,
  ViewChild,
} from '@angular/core';
import { AsyncPipe, NgClass, NgStyle } from '@angular/common';
import { CDK_DRAG_CONFIG, DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CleanUp } from '../../../../../../shared/cleanup';
import { SelectOption } from '../../../../../models/ui/select-option.model';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbPopover, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectComponent } from '../../../../../../shared/select/multi-select.component';
import {
  faGripHorizontal,
  faGripVertical,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { ChipComponent } from '../../../../../../shared/chips/chip/chip.component';
import { sortAntibodyRegionByName } from '../../../../../../shared/sort.util';
import { currentValueAndChanges } from '../../../../../../shared/utils/forms';
import { FormErrorsComponent } from '../../../../../../shared/form-errors/form-errors.component';
import { toSignal } from '@angular/core/rxjs-interop';

@Injectable()
export class RegionsOptions {
  constructor(
    public regions: string[],
    public initialRegions: string[],
    public form: FormControl,
    public maxSelection: number,
  ) {}
}

@Component({
  selector: 'bx-sankey-regions-selector',
  templateUrl: './sankey-regions-selector.component.html',
  styleUrls: ['./sankey-regions-selector.component.scss'],
  standalone: true,
  imports: [
    AsyncPipe,
    DragDropModule,
    FontAwesomeModule,
    NgbTooltipModule,
    NgClass,
    MultiSelectComponent,
    FormsModule,
    ReactiveFormsModule,
    NgbPopover,
    ChipComponent,
    FormErrorsComponent,
    NgStyle,
  ],
  providers: [{ provide: CDK_DRAG_CONFIG, useValue: { zIndex: 2000 } }],
})
export class SankeyRegionsSelectorComponent extends CleanUp implements OnInit, OnDestroy {
  @ViewChild(NgbPopover) popover: NgbPopover;
  allOptions: SelectOption<string>[];
  initialRegions: string[];
  selectedOptions = signal<string[]>([]);
  unselectedOptions: Signal<SelectOption<string>[]>;
  provisionallySelectedCount: Signal<number>;
  canNotProvisionallyAddRegions: Signal<boolean>;
  unselectedCount: Signal<number>;
  addRegionsButtonText: Signal<string>;
  addProvisionalRegionsButtonText: Signal<string>;
  selectedCount: Signal<number>;
  multiSelectValues: Signal<string[]>;
  formControlValues: Signal<string[]>;
  formControl: FormControl<string[]>;
  multiSelectFormControl: FormControl<string[]>;
  MAX_SELECTION: number;

  constructor(private regions: RegionsOptions) {
    super();
    this.allOptions = this.regions.regions.map((region) => new SelectOption(region, region));
    this.initialRegions = regions.initialRegions.filter((region) =>
      this.allOptions.some((option) => option.value === region),
    );
    this.MAX_SELECTION = regions.maxSelection;
    this.selectedCount = computed(() => this.selectedOptions().length);
    this.multiSelectFormControl = new FormControl<string[]>([], (ctrl) =>
      Validators.maxLength(this.MAX_SELECTION - this.selectedCount())(ctrl)
        ? { maxLengthMessage: `At most ${this.MAX_SELECTION} regions can be selected` }
        : null,
    );
    this.multiSelectValues = toSignal(
      currentValueAndChanges(this.multiSelectFormControl, this.ngUnsubscribe),
    );
    this.unselectedOptions = computed(() => {
      const selectedOptions = this.selectedOptions();
      return this.allOptions.filter((option) => !selectedOptions.includes(option.value));
    });
    this.unselectedCount = computed(() => this.unselectedOptions().length);
    this.formControl = regions.form;
    this.formControlValues = toSignal(currentValueAndChanges(this.formControl, this.ngUnsubscribe));
    this.formControl.setValue([]);
    this.provisionallySelectedCount = computed(() => {
      return this.multiSelectValues().length;
    });
    this.canNotProvisionallyAddRegions = computed(() => {
      const provisionally = this.provisionallySelectedCount();
      const selected = this.selectedCount();
      return provisionally + selected >= this.MAX_SELECTION + 1;
    });
    this.addProvisionalRegionsButtonText = computed(() => {
      const provisionally = this.provisionallySelectedCount();
      if (provisionally === 0) {
        return 'Select region(s)';
      } else if (provisionally === 1) {
        return 'Add 1 region';
      } else {
        return `Add ${provisionally} regions`;
      }
    });
    this.addRegionsButtonText = computed(() => {
      const selected = this.selectedOptions();
      const remaining = this.unselectedCount();
      if (remaining === 0) {
        return 'All Regions Selected';
      } else if (selected.length >= this.MAX_SELECTION) {
        return `At most ${this.MAX_SELECTION} regions can be selected`;
      } else {
        return 'Add Regions';
      }
    });
  }

  ngOnInit() {
    this.handleInitialRegions();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  openPopover() {
    if (this.selectedCount() < this.MAX_SELECTION && this.unselectedCount() > 0) {
      this.popover.open();
    }
  }

  onDropped(event: any) {
    const newSelectedOptions = [...this.selectedOptions()];
    moveItemInArray(newSelectedOptions, event.previousIndex, event.currentIndex);
    this.selectedOptions.set(newSelectedOptions);
    this.formControl.setValue(newSelectedOptions);
  }

  onPopoverHidden() {
    this.multiSelectFormControl.setValue([]);
  }

  onOptionsSelected() {
    const newOptions = this.multiSelectFormControl.getRawValue();
    const currentState = this.selectedOptions();
    const updatedOptions = [...currentState, ...newOptions];
    this.selectedOptions.set(updatedOptions);
    this.formControl.setValue(updatedOptions);
    this.popover.close();
  }

  onOptionRemoved(option: string) {
    let newSelected = this.selectedOptions();
    let newUnselected = this.unselectedOptions();
    newSelected = newSelected.filter((selected) => selected !== option);
    this.selectedOptions.set(newSelected);
    newUnselected = [...newUnselected, { displayName: option, value: option }];
    newUnselected.sort((a, b) => sortAntibodyRegionByName(a.value, b.value));
    this.formControl.setValue(newSelected);
    this.multiSelectFormControl.setValue([]);
  }

  trackByValue(index: number, option: string) {
    return option;
  }

  handleInitialRegions() {
    this.selectedOptions.set(this.initialRegions);
    this.multiSelectFormControl.setValue([]);
    this.formControl.setValue(this.initialRegions);
  }

  protected readonly faPlus = faPlus;
  protected readonly faTimes = faTimes;
  protected readonly faGripVertical = faGripVertical;
}
